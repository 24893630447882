import React from "react";
import loadable from '@loadable/component';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import log from 'loglevel';
import GridContainer from "components/Grid/GridContainer.jsx";
import i18n from "i18next";
import {
	Card,
	CardBody,
} from "reactstrap";
import axios from "axios";
import { IconButton, Grid } from "@mui/material";
import icon7 from "assets/img/icon7.png";
import jwt from "jwt-decode";
import { is_g_comp, ourGreen, ourOrange, ourLightGreen, isAuthenticated,permissions } from "assets/jss/incept-sustainability-variables.jsx";
//Buttons
import ActionButton from "components/CustomButtons/ActionButton";
//Modals
import Modal from "react-bootstrap/Modal";
import { default as CustomModal } from "components/Modal/Modal.jsx";
import ReactHtmlParser from "react-html-parser";
//Certificate PDF
//import Certificate from "components/PDF/Certificate";
//import { BlobProvider } from '@react-pdf/renderer';

import { fetchGuides } from './api/fetchGuides.js';


//Translations
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import Plausible from 'plausible-tracker';
import PageWrapper from "components/PageWrapper/PageWrapper.jsx";
const { trackEvent } = Plausible();

const style = (theme) => ({
	typo: {
		paddingLeft: "25%",
		marginBottom: "40px",
		position: "relative",
	},
	titlepackage: {
		fontFamily: "gotham-font-medium",
		marginBottom: "1.5rem",
		margin: "0",
		paddingTop: "1.5rem",
		"@media (max-width: 900px)": {
			textAlign: "center"
		}
		//marginLeft: "1.2rem"
	},
	lineunder: {
		display: "block",
		border: "1px solid #116628",
		marginLeft: "-60px",
		width: "50%",
		marginBottom: "40px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	buttoncolor: {
		backgroundColor: "yellow",
		borderColor: "#0095a9",
	},
	wrapper: {
		/*marginTop: "-9.5rem",*/
		marginRight: "2rem",
		marginBottom: "40px",
		[theme.breakpoints.down('md')]: {
			margin: "0",
		},
	},
	containerother: {
		paddingLeft: "40px",
		marginRight: "auto",
		marginLeft: "auto",
		[theme.breakpoints.down('md')]: {
			padding: "0 25px",
		},
	},
	kickOff: {
		borderRadius: "16px",
		padding: "10px 10px 0 10px",
		backgroundColor: ourLightGreen,
		//border: "1px solid rgba(172,208,146,.3)",
		height: "100%",
		"& p, strong": {
			fontSize: "1rem",
		},
		"& p": {
			fontSize: ".875rem",
		}
	},
	didYouKnow: {
		borderRadius: "16px",
		padding: "1rem 1rem 0 1rem",
		backgroundColor: "white",
		height: "100%",
		"& p, strong": {
			color: "black",
			fontSize: "1rem",
			[theme.breakpoints.down('md')]: {
				marginRight: "0",
			},
		},
		"& p": {
			fontSize: ".875rem",
		}
	},
	didYouKnowItem: {
		paddingBottom: "20px", 
	},
	"@supports not (-ms-ime-align: auto)": {
		buttonContainer: {
		  //justifyContent: "space-evenly !important"
		  textAlign: "center"
		}
	  },
	moduleTitle: {
		marginTop: "20px",
		marginBottom: "15px",
		fontWeight: "bold",
		[theme.breakpoints.down('md')]: {
			marginTop: "1rem",
		},
	},
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right",
		"@media (max-width: 600px)": {
			marginTop: "20px",
		},
	},
	modalBox: {
		"& .modal-content": {
			borderRadius: "2rem",
		}
	},
	infopackage: {
		marginBottom: "50px",
		"@media (max-width: 900px)": {
			marginBottom: "1rem"
		}
	},
});

const ListModules = loadable(() => import('./ListModules'));

  
class GuidesPage extends React.Component {
	constructor(props) {
		super(props);
		this.routeChange = this.routeChange.bind(this);
		var decodedtoken = "";
		if (isAuthenticated()) {
			decodedtoken = jwt(localStorage.getItem("token"));
		}
		const hasEverShowedEndModulesModal = JSON.parse(localStorage.getItem('hasEverShowedEndModulesModal') ?? 'false');

		this.state = {
			writeModule: permissions.get(decodedtoken.role)?.WriteModule,
			numModules: 0,
			all_modules: [],
			allcompleted: 0,
			showAboutModal: false,
			aboutModalNumber: 0,
			aboutModalTitle: "",
			aboutModalDes: "",
			aboutModalImg: "",
			loading: true,
			company: (decodedtoken!=="") ? decodedtoken.company:"",
			userid: (decodedtoken!=="") ? decodedtoken.userid:"",
			showEndModulesModal: !hasEverShowedEndModulesModal,
			errorBoundary: false,
			errorMessage: "",
			complete_before: "",
			greenbox: "",
			whitebox: ""
		};
	}



	showHideModal = (moduleNumber, moduleTitle, description, img) => {
		try {
			this.setState({
				showAboutModal: !this.state.showAboutModal,
				aboutModalNumber: moduleNumber,
				aboutModalTitle: moduleTitle,
				aboutModalDes: description,
				aboutModalImg: img
			});
			trackEvent('Go to -About- Module',{props: {Module: moduleTitle}});
		}
		catch(error) {
			throw new Error("Component: GuidesPage - showHideModal");
		}
	}

	handleEndModulesModalHide = () => {
		try {
			this.setState({ showEndModulesModal: false });
		}
		catch (error) {
			throw new Error("Component: GuidesPage - handleEndModulesModalHide");
		}
	};

	error_ocurred = (msg = "") => {
		this.setState({errorBoundary:true,errorMessage:msg})
	}

	/*
	 * Getting info about guides
	 *
	 */
	async getcompletedguides(categoryid) {
		this.setState({ loading: true });
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem("token");
		const catparsed = (categoryid===":id") ? "0" : categoryid;
		const insdata = {
			categoryid: catparsed
		};
		const useLang = i18n.language;
		try {
			const data = await fetchGuides(token, insdata, useLang);
			this.setState({
				...data,
				loading: false
			});
		} catch (err) {
			this.setState({ error: err, loading: false, errorMessage:  err });
			this.setState({ loadingfirst: false });
		}
	}
	componentDidMount() {
		const params = this.props.match.params;
		this.getcompletedguides(params.id);
		i18next.on('languageChanged', () => this.getcompletedguides());
	}
	componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.location !== prevProps.location) {
            const params = this.props.match.params;
			this.getcompletedguides(params.id);
        }
    }
	/*
	 *
	 */

	routeChange() {
		let path = `/guidesNav/1`;
		this.props.history.push(path);
	}
	toggle() {
		try {
			this.setState((prevState) => ({
				modal: !prevState.modal,
			}));
		}
		catch(error) {
			throw new Error("Component: GuidesPage - toggle");
		}
	}

	button_word(started, completed, t) {
		if (started===1 && completed===1) {
			return t("Recap");
		}
		else if (started===1) {
			return t("Continue");
		}
		else {
			return t("Start");
		}
	}

	render() {
		const { classes, t } = this.props;		
		if (this.state.errorBoundary) {
			throw new Error("Error: "+this.state.errorMessage);
		}
		return (
            <PageWrapper
				activeOverlay={this.state.loading}
			>
				{this.state.errorMessage==="" &&
				<div className={classes.containerother}>
					<div className={classes.wrapper}>
						<h2 className={classes.titlepackage}> {t('Modules')} </h2>
						<GridContainer>
							<GridItem xs={12} sm={12} md={5} space={0} otherclasses={classes.infopackage} >
								<Card className={classes.kickOff}>
									<CardBody style={{ padding: "1rem"}}>
										{ReactHtmlParser(this.props.databackend.greenbox)}
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={12} sm={12} md={7} otherclasses={classes.infopackage} spaceLeft={0}> 
								<Card className={classes.didYouKnow}>
									<CardBody
										style={{
											padding: ".4rem",
										}}
									>
										{ReactHtmlParser(this.props.databackend.whitebox)}
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
						<div className={classes.didYouKnowItem}>
						<GridContainer>
								{!this.state.writeModule &&
								<GridItem xs={12} >
									<Card className={classes.didYouKnow}>
										<CardBody
											style={{
												color: "white",
												padding: ".4rem",
											}}
										>
											<p style={{ fontSize: ".875rem" }}>
											{t('Analytics not record')}
											</p>
										</CardBody>
									</Card>
								</GridItem>
								}
								{false && (!this.state.loading) && this.state.all_modules.length > 0 && this.state.all_modules.every((elem) => elem.completed === 1) &&
								<GridItem xs={12} >
									<Card className={classes.didYouKnow}>
										<CardBody
											style={{
												color: "white",
												padding: ".4rem",
											}}
										>
											<p>{t('Congratulations on finishing the package!')}&nbsp;&nbsp;&nbsp;&nbsp;
                      								<ActionButton size="large"
													onClick={() => this.setState({ showEndModulesModal: true })}>{t('Get certificate')}</ActionButton></p>
										</CardBody>
									</Card>
								</GridItem>
							}
						</GridContainer>
						</div>

						<ListModules 
						list_modules={this.state.all_modules}
						showHideModal={this.showHideModal}
						iskb={false}
						button_word={this.button_word}
						startModule={"/0"}
						type={"/act/"}
						 t={t} 
						 />
						
						<Modal
							id="3"
							show={this.state.showAboutModal}
							onHide={() => this.setState({ showAboutModal: false })}
							centered
							size="lg"
							className={classes.modalBox}
						>
							<Modal.Body>
								<IconButton
                                    onClick={() => this.setState({ showAboutModal: false })}
                                    className={classes.modalCancelButton}
                                    size="large">
									<img
										src={icon7}
										alt="cancel icon"
										width="40"
										height="40"
									/>
								</IconButton>
								<div style={{ margin: "20px" }}>
									<h4 style={{ marginBottom: "15px", fontSize: "1.3rem"}}>
										{this.state.aboutModalTitle}
									</h4>
									<GridContainer>
										<GridItem xs={12} sm={8} md={8} space={0} style={{paddingRight:"20px"}}>
											{ReactHtmlParser(this.state.aboutModalDes)}
										</GridItem>
										<GridItem xs={12} sm={4} md={4} spaceLeft={0}>
											<img
												src={this.state.aboutModalImg}
												alt="cancel icon"
												className={"responsive"}
											/>
										</GridItem>
									</GridContainer>
								</div>
							</Modal.Body>
						</Modal>
						<CustomModal
							show={false && (!this.state.loading) && this.state.all_modules.length > 0 && this.state.all_modules.every((elem) => elem.completed === 1)
								&& this.state.showEndModulesModal}
							onHide={this.handleEndModulesModalHide}
							onShow={() => localStorage.setItem('hasEverShowedEndModulesModal', JSON.stringify(true))}
						>
							<h2>{t('Congratulations')}</h2>
							<h5>{t('You have finished the Incept Sustainability learning course')}</h5>
							<Grid container spacing={2}>
								<Grid item sm style={{ textAlign: "right" }}>
									{/*this.state.userrealname && this.state.date_completed ?
										<BlobProvider document={<Certificate name={this.state.userrealname} date={this.state.date_completed} />} fileName="certificate.pdf" >
											{({ blob, url, loading, error }) =>
											(loading ? t('Loading document', { defaultValue: 'Loading document...' })
												: <ActionButton size="large" href={url} target="_blank">{t('Download PDF!')}</ActionButton>)}
										</BlobProvider>
										: "Loading data..."
									*/}
								</Grid>
								<Grid item sm>
									<ActionButton size="large" lighten onClick={this.handleEndModulesModalHide}>
										{t('Cancel')}
									</ActionButton>
								</Grid>
							</Grid>
						</CustomModal>
					</div>
				</div>
				}
				{this.state.errorMessage!=="" &&
				<div className={classes.containerother}>
					<div className={classes.gridWrappper} style={{marginTop:"30px"}}>
					<h2>Well, this was unexpected. </h2>
					<p>
						Sorry, this is not working properly. We now know about
						it and are working to fix it.
					</p>
					<p>In the meantime, here is what it can be done:
						<ul>
							<li>Refresh the page (sometimes this helps).</li>
							<li>Make sure you have internet connection.</li>
							<li>Try again in 30 minutes.</li>
							<li>Email us at inceptsustainability@ramboll.com and tell us what happened.</li>
						</ul>
					</p>
					</div>
				</div>
				}
			</PageWrapper>
        );
	}
}

GuidesPage.propTypes = {
	classes: PropTypes.object,
};

export default withTranslation()(withStyles(style)(GuidesPage));
