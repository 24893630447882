import {Dispatch, SetStateAction} from "react";
import {IdeasAndCommentsFilter} from "../BaseFilters/IdeasAndCommentsFilter";
import {AnalyticsIdeasFilter} from "../interfaces/AnalyticsIdeasFilter";
import useIdeasFilter from "../../../hooks/useIdeasFilter";

class DepartmentFilter<T extends number, K extends ReturnType<typeof useIdeasFilter>> extends IdeasAndCommentsFilter<T, K> implements AnalyticsIdeasFilter<T, K> {

    filter(deptId: T, setSelfId: Dispatch<SetStateAction<T>>, ideasFilterData: K): void {
        setSelfId(deptId);
        const ideasFilteredL = ideasFilterData.ideasInfo.ideas.filter((idea) => parseInt(idea.departmentid) === deptId);

        if (ideasFilteredL.length < 5) {
            ideasFilterData.setIdeasFiltered([]);
            ideasFilterData.setIdeasInfo({
                ...ideasFilterData.ideasInfo,
                nideas: 0,
                nparticipants: 0,
            });
            ideasFilterData.setListItems([]);
            return;
        }

        ideasFilterData.setIdeasFiltered(ideasFilteredL);
        ideasFilterData.setIdeasInfo({
            ...ideasFilterData.ideasInfo,
            nideas:
                typeof ideasFilterData.ideasInfo.departmentStats[deptId] !== undefined
                    ? ideasFilterData.ideasInfo.departmentStats[deptId].ncount
                    : 0,
            nparticipants:
                typeof ideasFilterData.ideasInfo.departmentStats[deptId] !== undefined
                    ? ideasFilterData.ideasInfo.departmentStats[deptId].npercentage
                    : 0,
        });

        ideasFilterData.setListItems(ideasFilteredL)
    }

    clear = (ideasFilterData: ReturnType<typeof useIdeasFilter>): void =>  {
        this.filter(0 as T, ideasFilterData.setCurrentDepartment as Dispatch<SetStateAction<T>>, ideasFilterData as K);
    }

    public static createFilter = <T extends number, K extends ReturnType<typeof useIdeasFilter>> (): DepartmentFilter<T, K> => new DepartmentFilter<T, K>()
    public static getClassName = (): string => "DepartmentFilter";



}

export { DepartmentFilter }
