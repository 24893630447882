import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Card from "components/Card/Card.jsx";
import TextArea from "components/CustomInput/TextArea";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { ourDarkGreen, standardRadious } from "assets/jss/incept-sustainability-variables.jsx";
import axios from "axios";
import { module_idea_update } from "assets/jss/incept-sustainability-variables";
import { useSharedTokenInfo } from "context/auth_context/TokenContext";

const styles = {
    root: {
        "@media only screen and (max-width: 600px)": {
            margin: "0 20px 0 10px"
        }
    },
    buttonContainer: {
        textAlign: "right",
    },
    cardAnswer: {
        borderRadius: standardRadious,
        "@media only screen and (max-width: 960px)": {
            borderRadius: "1rem",
            paddingTop: "17px"
        }
    },
    showmessage: {
        display: "block",
        color: ourDarkGreen
    },
    nshowmessage: {
        display: "none"
    },
    footertext: {
        fontStyle: "italic",
        fontSize: "11px",
        marginBottom: "5px"
    },
    textField: {
        '& .MuiInput-underline:after ': {
            borderBottom: "2px solid " + ourDarkGreen,
        }
    }
};

const IdeaGeneratorBody = ({ 
    classes, 
    t, 
    ideaId,
    value,
    onSubmit,
    moduleId
    }) => {
    const [messagesent, setMessagesent] = useState(false);
    const [userAnswer, setUserAnswer] = useState(value);

    const {authString} = useSharedTokenInfo();
    const authHeader = `Bearer ${authString}`;

    const handleChange = (e) => {
        setUserAnswer(e.target.value);
        setMessagesent(false);
    };

    const handleSubmit = async() => {
        const endpoint = module_idea_update(moduleId, ideaId);
        try {
            await axios.post(endpoint, {
            answer: userAnswer,
            }, 
            {
            headers: {
                'Authorization': `${authHeader}`
            }
            });
            setMessagesent(true);
            onSubmit?.(ideaId, userAnswer || "");
        } catch (error) {
            console.error("Error submitting idea:", error);
    }};

    return (
        <div className={classes.root}>
            <Card style={{border: `1px solid ${ourDarkGreen}`, marginBottom: "5px"}}>
                <Container>
                    <TextArea
                        onChange={handleChange}
                        value={userAnswer}
                        borderColor={ourDarkGreen}
                        placeholder={t('I believe we can be better at...')}
                        borderRadius={standardRadious}
                        variant="standard"
                        sx={{'& ::placeholder':{fontSize:'small'}, paddingTop: "10px"}}
                        InputProps = {{
                            disableUnderline: true
                        }}
                    />

                    <p className={messagesent ? classes.showmessage : classes.nshowmessage}>
                        {t('Thank you for submitting your answer')}
                    </p>
                </Container>
                        
            </Card>
            <p className={classes.footertext}>
                {t('no personal data', {
                    defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'
                })}
            </p>
            <Container className={classes.buttonContainer}>
                 <ActionButton
                     style={{width:"auto"}}
                     onClick={handleSubmit}                     
                 >
                     {t('Submit idea')}
                 </ActionButton>
            </Container>
        </div>
    );
};

export default withTranslation()(withStyles(styles)(IdeaGeneratorBody));