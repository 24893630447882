/*!
 * Description: this file contains the main base component for
 *  the rendering the main page view of analytics
 *
 * Components: Analytics_New
 */
import React, { useState, useEffect, useRef  } from "react";
import loadable from '@loadable/component'
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import Grid from "@mui/material/Grid";
import loadinggif from "assets/img/loading.gif";
import moreInfo from 'assets/img/moduleIcon.svg';
import ResponsiveLine from "components/Nivo/Line.jsx";
import { BasicTooltip } from '@nivo/tooltip';
import icon7 from "assets/img/icon7.png";
import {
    n_comp_pck, ourGreen, ourDarkGreen, ourBlueLight,
    ourBlue, isAuthenticated
} from "assets/jss/incept-sustainability-variables.jsx";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import DatePicker from 'react-date-picker';
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
//import { ResponsiveLine } from '@nivo/line'
import FakeButton from "components/CustomButtons/FakeButton";
import ProgressButton from 'components/CustomButtons/Progressbutton.jsx'
import ActionButton from 'components/CustomButtons/ActionButton';
import jwt from "jwt-decode";
//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';
//styles
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { useMediaQuery } from 'react-responsive';
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";

import "react-circular-progressbar/dist/styles.css";
import downloaddata from "assets/img/downloaddata.png";
import ReactExport from "react-data-export";
import axios from "axios";
//Translate
import { withTranslation, Trans,useTranslation } from 'react-i18next';
import i18n from "i18next";
import General_click_tooltip from "components/Tooltip/General_click_tooltip";
import Button from '@mui/material/Button';
import AnalyticsCompany_FilterBar from "views/Analytics/AnalyticsCompany/AnalyticsCompany_FilterBar.jsx";
import Modal from "react-bootstrap/Modal";
import { IconButton } from "@mui/material";
import Form from "react-bootstrap/Form";

const useStyles = (theme) => ({
    smallButton: ({ color, textColor }) => ({
        backgroundColor: color,
        color: textColor,
        border: "0px",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "5px 30px",
        width: "100%"
    }),
    centerItems: {
        alignItems: "center",
        display: "flex"
    },
	spaceright: {
		marginRight: "10px",
        marginBottom: "0"
	},
	spaceright2: {
		marginRight: "20px"
	},
	nograph: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		justifyContent: "center",
		fontStyle:"italic"
	},
    alignRight: {
        textAlign: "right",
        paddingRight: "0.3rem"
    },
    downloadExcel: {
        marginLeft: "10px",
        "& span": {
            display: "inline-block",
            width: "1px"
        },
        "& .MuiButton-label": {
            display:"contents"
        }
    },
    ButtonDownload: {
        minWidth: "auto",
        padding: "0",
        zIndex: "1999"
    },
    modalCancelButton: { 
		right: "0",
		position: "relative", 
		zIndex: "3", 
		padding: "0px",
		float: "right"
	},
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({ query: `(max-width: 768px)` });
        const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
        return <Component {...props} isMobile={mobile} isTablet={isTablet} />;
    }
}

const ModuleCard = loadable(() => import('./ModuleCard.jsx'));

function Analytics_CompletionLog(props) {
    const { classes, content } = props;
    var decodedtoken = "";
	var redi = false;
	if (!isAuthenticated()) {
		redi = true;
	}
	else
		decodedtoken = jwt(localStorage.getItem('token'));
	let roleaux = decodedtoken.role;
	const [isCustomManager, setCustomManager] = useState((roleaux==="Custom Manager" || roleaux === "Custom Learner Manager") ? true:false);
    const [loadingfirst, setloadingfirst] = useState(true);
    const [currentDepartment, setcurrentDepartment] = useState(0);
	const [currentCountry, setcurrentCountry] = useState([]);
    var d = new Date();
    const prior = new Date().setDate(d.getDate() - 30);
    const [fromdate, setfromdate] = useState(new Date(prior));
    const [todate, settodate] = useState(new Date());
    const [disable1, setdisable1] = useState(false);
    const [loading1, setloading1] = useState(true);
    const [exportTextModal, setexportTextModal] = useState("");
    const [exportdataCL, setexportdataCL] = useState([]);
    const [exportdataCLCT, setexportdataCLCT] = useState([]);
    const [exportdataCLGY, setexportdataCLGY] = useState([]);
    const [exportdataload, setexportdataload] = useState(false);
    const [exportdataloading, setexportdataloading] = useState(false);
    const [modulesInfoCompletion, setmodulesInfoCompletion] = useState([]);
    const [modulesInfo, setmodulesInfo] = useState([]);
    const [departments, setDepartments] = useState([]);
	const [countries, setCountries] = useState([]);
    const [filterFlags, setFilterFlags] = useState({department:0,country:0});
    const [completionData, setcompletionData] = useState({registered:0,active:0,nuasers:0,completedusers:0,completed:0});
    const [graphInfo, setgraphInfo] = useState({module1_facts: [],alllearnings:[],lineData:{
        "id": "participants",
        data: [{ x: "01-05-2019", y: -1 }],
        nDays: 0,
    }});
    const { t, i18n } = useTranslation();
    const multiselectRef = useRef(null);
    const downloadExcelReg = useRef(null);
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

	function resetValues() {
		// By calling the belowe method will reset the selected values programatically
		multiselectRef.current.resetSelectedValues();
	}
	
    useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		if (loadingfirst) {
			getncomplpack();
		}
	  }, []);

    

	//Get ideas from backend
	const getncomplpack = async (depid="",countries=[]) => {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		const urlapi = n_comp_pck+"engagement?wpml_language="+ useLang;
        setFilterFlags({department: (depid!=="") ? 1:0,country:(countries.length>0) ? 1:0});
        let insdata = {
			headers: { Authorization: authstr },
			params: {
				fromdate: fromdate,
				todate: todate,
                department:depid,
                countries: JSON.stringify(countries)
			},
		  }
		axios.get(n_comp_pck + "engagement?wpml_language="+ useLang, insdata)
			.then((response) => {
                let tickdays = "1";
				if (response.data.days.length>0) {
					var first_array =response.data.days[0].x.split('-');
					var firstday = new Date(first_array[2], first_array[1] - 1, first_array[0]); 
					var last_array =response.data.days[response.data.days.length-1].x.split('-');
					var lastday = new Date(last_array[2], last_array[1] - 1, last_array[0]); 
					const diffInMs   = lastday - firstday;
					const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
					if (diffInDays>30)
						tickdays = "11";
					else if (diffInDays>5)
						tickdays = "5";
				}	
                setgraphInfo({
					module1_facts: response.data.learnednew,
					alllearnings: response.data.alllearnings,
					lineData: 
						{
							"id": "participants",
							data: (response.data.days.length>0) ? response.data.days:[{ x: "01-05-2019", y: -1 }],
							nDays: tickdays,
						}
				});
                setcompletionData({
                    registered: response.data.allusers,
                    active: response.data.nactive,
                    completed: response.data.completed,
                    nuasers: response.data.nuasers,
                    completedusers: response.data.completedusers
                })
                setDepartments(response.data.departments);
				setCountries(response.data.countries);
                setmodulesInfo(response.data.infoModules);
                setmodulesInfoCompletion(response.data.module_completion);
                setloading1(false);
                setloadingfirst(false);
			})
			.catch((err) => {
				console.log(err);
				setloading1(false);
                setloadingfirst(false);
			});
	}

    const setStartDate  = (date,where) => {
		if (where===0) {
			if (date>todate) {
				alert("'From' date cannot be after 'to' date");
				return;
			}
			setfromdate(date);
		}
		else {
			if (date<fromdate) {
				alert("'To' date cannot be before 'from' date");
				return;
			}
			settodate(date);
		}
	}

    const handleSubmit  = (e) => {
		setloading1(true);
        getncomplpack();
	}

    const ModulesSections = modulesInfo.map((module, index) => (
            <ModuleCard
			key={module.name}
            moduleName={module.name}
            moduleDept={module.departments}
            nactive={completionData.nuasers}
            />
    ));

    const filterSectionsDep = (event) => {
		setcurrentDepartment(event.target.value);
	}

    function filter_country_multichoice(selectedList, selectedItem) {
        setcurrentCountry(selectedList);
    }

    function filter_results() {
        setloadingfirst(true);
        getncomplpack(currentDepartment,currentCountry);
    }

    function clearFilters() {
        if (filterFlags.country===0 && filterFlags.department===0)
            return;
        resetValues();
        setloadingfirst(true);
        setcurrentCountry([]);
        setcurrentDepartment(0);
        getncomplpack("",[]);	
    }

    let lengthGrid =  Math.round((modulesInfoCompletion.length)/2);
    const CompletionModulesTable = modulesInfoCompletion.map((number, index) => {
        if (index>=lengthGrid)
            return;
        return (
            <TableRow
            key={`${parseInt(index) + 1} ${t("Modules")}`}
            >
            <TableCell component="th" scope="row" className={classes.completionRowTh} style={{width:"100px"}}>
                {`${parseInt(index) + 1} ${index === 0 ? t("Module") : t("Modules")}: `}
            </TableCell>
            <TableCell align={"right"} className={classes.completionRow} style={{maxWidth:"80px",textAlign:"left"}}>
                <FakeButton
                style={{
                    width:"80px"
                }}
                color={ourBlueLight}
                textColor={ourBlue}
                content={number} />
            </TableCell>
            </TableRow>
        );
        
    });

    const CompletionModulesTable2 = modulesInfoCompletion.map((number, index) => {
        if (index<lengthGrid)
            return;
        return (
            <TableRow
            key={`${parseInt(index) + 1} ${t("Modules")}`}
            >
            <TableCell component="th" scope="row" className={classes.completionRowTh} style={{width:"90px",textAlign:"right",paddingRight:"0"}}>
                {`${parseInt(index) + 1} ${t("Modules")}: `}
            </TableCell>
            <TableCell align={"right"} className={`${classes.completionRow} ${classes.alignRight}`} style={{width:"80px"}}>
                <FakeButton
                style={{
                    width:"80px"
                }}
                color={ourBlueLight}
                textColor={ourBlue}
                content={number} />
            </TableCell>
            </TableRow>
        );
    });

    const downloadDataExcelModal = e => {
        //Get the selected department name
        let textdepartment = (currentDepartment===0 ? t("all"):"");
        if (currentDepartment!==0) { //If it is not "all" departments
            departments.map((element, _) => {
                if (element.id===currentDepartment) 
                    textdepartment = element.name;
            });  
        }
        //Get the selected countries
        let textcountries = "all";
        currentCountry.map((element, index) => {
            if (index===0) 
                textcountries = "";
            textcountries += element.name;
            if (index!==(currentCountry.length-1))
                    textcountries += ", ";
            return textcountries;
        });        
        setexportTextModal(` ${textdepartment} ${t("market")} ${t("and")} ${t(textcountries)} ${t("geography")}`);
        setexportdataload(true);  //Show modal
    }

    const downloadDataExcel = e => {
        //fetch data        
        setexportdataloading(true);
        const token = localStorage.getItem("token");
        const authstr = "Bearer " + token;
        const useLang = i18n.language;
        const urlapi = n_comp_pck+"engagementExport?wpml_language="+ useLang;
        let insdata = {
            headers: { Authorization: authstr },
            params: {
                department: currentDepartment,
                countries: JSON.stringify(currentCountry)
            },
        }
        axios.get(urlapi, insdata)
            .then((response) => {                
                let colTitles = [{title: "Market"},
                    {title: "Registered", width: {wpx: 70}},
                    {title: "Active", width: {wpx: 50}},
                    {title: "Completed One", width: {wpx: 100}},
                    {title: "Completed All", width: {wpx: 100}},
                    {title: "(Past 30 days) Registered", width: {wpx: 120}},
                    {title: "(Past 30 days) Active", width: {wpx: 120}},
                    {title: "(Past 30 days) Completed One", width: {wpx: 120}},
                    {title: "(Past 30 days) Completed All", width: {wpx: 120}},
                    {title: "(Past 7 days) Registered", width: {wpx: 120}},
                    {title: "(Past 7 days) Active", width: {wpx: 120}},
                    {title: "(Past 7 days) Completed One", width: {wpx: 120}},
                    {title: "(Past 7 days) Completed All", width: {wpx: 170}},
                ];
                const exportDataComplLog = [
                    {
                    columns: colTitles,
                    data: response.data.marketsData,
                    },
                ];
                setexportdataCL(exportDataComplLog);
                let colTitles1 = JSON.parse(JSON.stringify(colTitles));
                colTitles1[0].title = "Country";
                const exportDataComplLogCT = [
                    {
                    columns: colTitles1,
                    data: response.data.countryData,
                    },
                ];
                setexportdataCLCT(exportDataComplLogCT);
                let colTitles2 = JSON.parse(JSON.stringify(colTitles));
                colTitles2[0].title = "Geography";
                const exportDataComplLogGY = [
                    {
                    columns: colTitles2,
                    data: response.data.geoData,
                    },
                ];
                setexportdataCLGY(exportDataComplLogGY);
                setexportdataloading(false);
                setexportdataload(false);
                if (downloadExcelReg.current !== null) {
                    downloadExcelReg.current.click();
                }
            })
            .catch((err) => {
                setexportdataloading(false);
                setexportdataload(false);
                console.log(err);
            });        
    }

    return (
        <LoadingOverlay
				active={loadingfirst}
				spinner={<BounceLoader />}
			>
        <div className={classes.containerAnalytics}>
            <Grid>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} space={0} >
                        <h2 className={classes.titleHeader}>{t("Completion log")}
                            <span className={classes.downloadExcel}>
                                <ExcelFile filename={"completion_log_"+d.toISOString().slice(0,10)} element={<Button 
                                            innerRef={downloadExcelReg} ref={downloadExcelReg}
                                        />}>
                                    <ExcelSheet dataSet={exportdataCL} name="Market Data"/>
                                    <ExcelSheet dataSet={exportdataCLCT} name="Country Data"/>
                                    <ExcelSheet dataSet={exportdataCLGY} name="Geography Data"/>
                                </ExcelFile>
                                <Button className={classes.ButtonDownload} onClick={downloadDataExcelModal}><img
                                    className="responsive2"
                                    src={downloaddata}
                                    alt=""
                                /></Button>
                            </span>
                            <p style={{fontSize:"11px",lineHeight:"0",marginBottom:"40px",marginTop:"-25px"}}><i>{t("Time zone")}: GMT</i></p>
                        </h2>
                    </GridItem>
                </GridContainer>
                <div style={{margin:"10px 0 50px 0"}}>
                    <AnalyticsCompany_FilterBar 
                    modules={[]}	
                    isCustomManager={isCustomManager}
                    departments={departments}	
                    countries={countries}
                    activities={[]}	
                    currentDepartment={currentDepartment}
                    currentModule={0}	
                    currentActivity={0}
                    filterSections={null}
                    filterSectionsDep={filterSectionsDep}		
                    filterSectionsCountry={filter_country_multichoice}
					showGeographyFilter={true}
                    searchFilter={filter_results}
                    clearFilters={clearFilters}
                    multiselectRef={multiselectRef}
                    />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={5} space={0} style={props.isTablet ? { marginBottom: "30px" } : {}} >
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12} paddingHeightAlign={true} style={props.isTablet ? { marginBottom: "30px" } : {}} >
                        <CardAnalytics>
                            <CardAnalyticsHeader title={t("Overall completion rates")} >
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}  space={0} spaceNoPadding={true}>
                                        <TableContainer >
                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow
                                                        key={"Registered employees"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                            {t("Registered employees")+":"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow} style={{width: "80px"}} >
                                                        </TableCell>
                                                        <TableCell align={"right"} className={classes.completionRowTh2} style={{width:"80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width:"80px"
                                                                }}
                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={completionData.registered} />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={"Active employees"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh}>
                                                            {t("Active employees")+":"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={"white"}
                                                                atooltip={true}
                                                                textTooltip={t("% of the") +" "+completionData.registered+" " + t("registered employees")}
                                                                border={"1"}
                                                                borderColor={ourBlue}
                                                                textColor={ourBlue}
                                                                content={completionData.active+"%"} />
                                                        </TableCell>
                                                        <TableCell align={"right"} className={classes.completionRowTh2} 
                                                        style={{maxWidth:"80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width:"80px"
                                                                }}
                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={completionData.nuasers} />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={"Completed employees (all modules)"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh}>
                                                        <ActiveEmployees_tooltip tooltipid={"someid"} />{t("that have completed all modules")+":"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={"white"}
                                                                atooltip={true}
                                                                textTooltip={t("% of the")+ " " +completionData.nuasers+ " " + t("Active employees")}
                                                                border={"1"}
                                                                borderColor={ourBlue}
                                                                textColor={ourBlue}
                                                                content={completionData.completed+"%"} />
                                                        </TableCell>
                                                        <TableCell align={"right"} className={classes.completionRowTh2} style={{maxWidth:"80px"}}>
                                                            <FakeButton
                                                                style={{
                                                                    width:"80px"
                                                                }}
                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={completionData.completedusers} />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>

                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} space={0} style={props.isTablet ? { marginBottom: "30px" } : {}} >
                        <CardAnalytics>
                            <CardAnalyticsHeader title={t("Modules completed per employee")} >
                                <div style={{marginLeft:"auto",cursor:"pointer"}}>
                                    <General_click_tooltip
                                    toltipText={t("This section displays how many employees have completed 1 module, 2 modules, 3 modules, etc.")}
                                    >
                                    <img
                                        className="responsive2"
                                        src={moreInfo}
                                        alt=""
                                    />
                                    </General_click_tooltip>
                                </div>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody>
                                <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={6} space={0}> 
                                    <TableContainer >
                                        <Table aria-label="simple table">
                                            <TableBody>{CompletionModulesTable}</TableBody>
                                        </Table>
                                    </TableContainer >
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={6} space={0} spaceNoPadding={true} >
                                    <TableContainer >
                                        <Table aria-label="simple table">
                                            <TableBody>{CompletionModulesTable2}</TableBody>
                                        </Table>
                                    </TableContainer >
                                 </GridItem>
                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                        </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={7} space={0} style={props.isMobile ? { marginBottom: 30 } : {}}>
                        <CardAnalytics style={{ height: "100%" }}>
                            <CardAnalyticsHeader title={t("Progress")} >
                                <div style={{marginLeft:"auto",cursor:"pointer"}}>
                                    <General_click_tooltip
                                    toltipText={t("Use this section to see how many employees have completed a module per day during a time range of your choosing")}
                                    >
                                    <img
                                        className="responsive2"
                                        src={moreInfo}
                                        alt=""
                                    />
                                    </General_click_tooltip>
                                </div>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody>
                                <div style={{marginBottom:"30px"}}>
                                    <p>
                                        {t('Select a time range below')}:
                                    </p>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} lg={5}>
                                            <p className={classes.spaceright}>{t("From")+":"}</p>
                                            <DatePicker
                                                format={"dd-MM-yyyy"}
                                                value={fromdate}
                                                onChange={(date) => setStartDate(date,0)}
                                                className={classes.spaceright2}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={7}>
                                            <GridContainer justifyContent="center" alignItems="center">
                                                <GridItem>
                                                    <p className={classes.spaceright}>{t("To")+":"}</p>
                                                    <DatePicker
                                                        format={"dd-MM-yyyy"}
                                                        value={todate}
                                                        onChange={(date) => setStartDate(date,1)}
                                                    />
                                                </GridItem>
                                                <GridItem>
                                                    <img
                                                        className="responsive"
                                                        src={loadinggif}
                                                        width="25"
                                                        alt=""
                                                        style={loading1 ? {display: "inline",margin: "20px 10px 0 0"}:{display:"none",margin: "20px 10px 0 0"}}
                                                    />
                                                    <ActionButton
                                                        type="submit"
                                                        style={{marginTop:"20px",marginBottom:0}}
                                                        onClick={(e) => handleSubmit(e)}
                                                        disabled={disable1}
                                                    >
                                                        {t('Filter')}
                                                    </ActionButton>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} space={0} className={classes.columnCardsPadding}>
                                        <div style={{ height: "300px", margin: "auto" }}>
                                            {graphInfo.lineData.data[0].y!==-1 && 
                                            ResponsiveLine(graphInfo.lineData,graphInfo.lineData.nDays,t)}
                                            {graphInfo.lineData.data[0].y===-1 &&
                                                <div className={classes.nograph}>
                                                    {t("Nothing to show during this time period")}</div>
                                            }
                                        </div>
                                    </GridItem>

                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                    </GridItem>
                    <Modal
                        id="4"
                        show={exportdataload}
                        onHide={() => {setexportdataload(false)}}
                        centered
                        size="lg"
                        className={classes.modalBox}
                    >
                        <Modal.Body>
                        <IconButton
                            onClick={() => {setexportdataload(false)}}
                            disabled={exportdataloading}
                            className={classes.modalCancelButton}
                            size="large">
                            <img
                            src={icon7}
                            alt="cancel icon"
                            style={{ width: "40px", height: "40px" }}
                            />
                        </IconButton>
                        <div style={{ padding: "2rem" }}>
                            <h5 style={{ fontWeight: "bold",marginTop:"20px" }}>{t('Export excel file')+":"}</h5>
                            <p>{t("Are you sure you want to export data to an excel file from ")}{exportTextModal}{"?"}</p>
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item sm style={{textAlign:"right"}}>
                                            <ActionButton size="large" onClick={(e) => downloadDataExcel()}>{t('Yes')}</ActionButton>
                                        </Grid>
                                        <Grid item sm>
                                            <ActionButton size="large" lighten onClick={(e) => setexportdataload(false)}>{t('Cancel')}</ActionButton>
                                        </Grid>
                                    </Grid>
                                </Form>
                            {exportdataloading && <div style={{ paddingTop: "20px" }}>
                                <img
                                    className="responsive"
                                    src={loadinggif}
                                    width="25"
                                    alt=""
                                />
                                </div>}
                        </div>
                        </Modal.Body>
                    </Modal>
                </GridContainer><br></br>
                <div style={{padding: "0 1rem 0 1rem"}}>
                <h3 style={{margin: "10px 0"}}>{t("Modules")}</h3>
                </div>
                <GridContainer>
                {ModulesSections}
                </GridContainer>


            </Grid>
        </div>
        </LoadingOverlay>
    );

}
export default
    withMyHook(withStyles(
        (theme) => ({
            ...analyticsStyle(theme),
            ...useStyles(theme),
        }),
        { withTheme: true },
    )(Analytics_CompletionLog));

