import React from 'react';
import { useLocation } from 'react-router-dom'; // Import hooks from react-router-dom
import NewAppIFrame from 'components/NewAppIFrameViewer';
import useKnowledgebaseHandler from 'components/Sidebar/hooks/useKnowledgebaseHandler';

const KnowledgeBase2 = (props) => {
  const location = useLocation();
  const id = location.pathname.split('/').pop();
  
  const { knowledgeBaseCategories } = useKnowledgebaseHandler();
  const name = knowledgeBaseCategories.find((category) => category.id === Number(id))?.name;

 
  // Ensure token is valid and categoryId is available
  if ( !id) {
    return null;
  }
  const iframePagePath = name ?`knowledgebase/${id}?name=${name}` : `knowledgebase/${id}`;


  return (
    <NewAppIFrame
      iFramePagePath={iframePagePath}
      title="Knowledge Base"
    />
  );
};
export default KnowledgeBase2;
