/*!
* Description: this file contains the component for
*  a section that has only content: ex. "Introduction"
* Components: GuideStandardCard
*/
import React, { useState, useLayoutEffect, useRef, forwardRef, useEffect } from 'react';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@mui/material/Container';
import withStyles from "@mui/styles/withStyles";
import Collapse from "@mui/material/Collapse";
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
import WrapperFeedback from "components/Feedback/WrapperFeedback.js";
import { ourOrange, ourGreen } from "assets/jss/incept-sustainability-variables";
import arrowdown from 'assets/img/down-arrow.svg';
import checkmark from 'assets/img/checkmark.png';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CarouselSection from "components/Exercise/CarouselSection.js";
import { Carousel } from 'react-responsive-carousel';
import greyCheckmark from 'assets/img/checkmark-grey.svg';
import greenCheckmark from 'assets/img/checkmark-green.svg';
import { useMediaQuery } from 'react-responsive';

import FlipCard from 'components/Card/FlipCard/FlipCard';
import moduleImage from 'assets/img/Module1image.png';
//Buttons
//Translations
import { withTranslation } from 'react-i18next';
import { card } from 'assets/jss/material-dashboard-react';

const styles = {
	root: {},
	cardColor: {
		backgroundColor: ourGreen,
		color: "white",
	},
	cardNoColor: {
		color: "#000000",
	},
	notopPadding: {
		padding: "0px 20px"
	},
	cardExample: {
		marginBottom: "10px",
		minHeight: "110px",
		marginTop: "0"
	},
	cardHeader: {
		borderBottom: 'none'
	},
	cardBodyText: {
		fontSize: ".95rem",
		"@media only screen and (max-width: 960px)": {
			marginTop: "30px",
		}
	},
	iconNavBack: {
		fontSize: '30px',
	},
	customDivider: {
		marginTop: '20px'
	},
	titlecolor: {
		color: "white"
	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "3px 20px 20px 0",
		paddingRight: "0",
		"@media only screen and (max-width: 960px)": {
			margin: "3px 20px 20px 0",
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
	checkmark: {
		"@media only screen and (max-width: 960px)": {
			float: "right",
		},
	},
	iconDownArrow: {
		cursor: "pointer",
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
	},
	heightFix: {
		height: "80vh",
		"@media only screen and (max-width: 960px)": {
			height: "auto"
		},
	},
	titlePage: {
		textAlign: "center",
		marginTop: "10px",
		"@media only screen and (max-width: 960px)": {
			fontSize: "0.70rem",
		},
	},
	cardHead: {
		alignItems: "center",
		justifyContent: "center",
		minHeight: "110px"
	},
	titleMargin: {
		marginLeft: "20px"
	},
	carouselPer: {
		"& .carousel": {
			margin: "0 auto",
			textAlign: "center"
		},
		"& .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover,.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover": {
			background: ourOrange,
		},
		"& .carousel .thumb.selected, .carousel .thumb:hover": {
			border: "3px solid " + ourOrange,
		},
		"& .carousel .control-dots .dot": {
			boxShadow: "2px 2px 3px " + ourOrange,
		}
	}
};

const flipCardsExample = [
	{
		front: {
			title: "This is the front of the card",
			background: "#ffffff"
		},
		back: {
			content: "This is the back of the card",
		},
		direction: "right"
	},
	{
		front: {
			title: "This is the front of the card (2)",
			background: "#009DE0"
		},
		back: {
			content: "This is the back of the card (2)",
		},
		direction: "right"
	},
	{
		front: {
			title: "This is the front of the card (3)",
			background: `${moduleImage}`
		},
		back: {
			content: "This is the back of the card (3)",
		},
		direction: "right"
	},
	{
		front: {
			title: "This is the front of the card (4)",
			background: `${moduleImage}`
		},
		back: {
			content: "This is the back of the card (4)",
		},
		direction: "right"
	},
	{
		front: {
			title: "This is the front of the card (5)",
			background: `${moduleImage}`
		},
		back: {
			content: "This is the back of the card (5)",
		},
		direction: "right"
	},
	// {
	// 	front: {
	// 		title: "This is the front of the card (6)",
	// 		background: `${moduleImage}`
	// 	},
	// 	back: {
	// 		content: "This is the back of the card (6)",
	// 	},
	// 	direction: "right"
	// },
	// {
	// 	front: {
	// 		title: "This is the front of the card (7)",
	// 		background: "#DC3545"
	// 	},
	// 	back: {
	// 		content: "This is the back of the card (7)",
	// 	},
	// 	direction: "right"
	// },
	// {
	// 	front: {
	// 		title: "This is the front of the card (7)",
	// 		background: "#DC3545"
	// 	},
	// 	back: {
	// 		content: "This is the back of the card (7)",
	// 	},
	// 	direction: "right"
	// },
]

function withMyHook(Component) {
	return function WrappedComponent(props) {
		const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
		const tablet = useMediaQuery({ query: `(max-width: 960px)` });
		const desktop = useMediaQuery({ query: `(max-width: 1200px)` });
		return <Component {...props} isMobile={mobile} isTablet={tablet} isDesktop={desktop} />;
	}
}

function GuideFlipCardsSection(props) {
	const { classes, location, content, title, t, numberColumns, saveresloc, stepn,
		isexercise, isitrich, textContent, cards, isFirstSection,
		showcard, openSection,
		readCard, datasav, smoothtrans, type, images, index } = props;
	const [isdefaultView, setisdefaultView] = useState((props.videoContent != "") ? true : false);
	const [defaultView, setdefaultView] = useState((props.videoContent != "") ? true : false);
	const [loading, setloading] = useState(true);
	const [notOpen, setnotOpen] = useState(true);


	const [cardArray, setCardArray] = useState([]);
	const cardRefs = useRef([]); // Create an array of refs

	const handleUpdateArray = (value) => {
		setCardArray(prevArray => [...prevArray, value]);
	};

	useLayoutEffect(() => {
		// Get the max number of card array
		// console.log(cardArray)
		const maxCardHeight = Math.max(...cardArray.map(card => card));
		if (cardArray.length > 0) {
			cardRefs.current.forEach((cardRef) => {
				cardRef.style.height = `${maxCardHeight}px`;
				console.log(maxCardHeight)
			});
		}
	}, [notOpen, cardArray, props.isMobile, props.isTablet, props.isDesktop]);





	// Calculate the grid size based on the number of cards per row (classis 12 grid system like MUI, that is why we divide by 12)
	const gridSize = Math.floor(12 / numberColumns);

	// If on mobile, you may want to override the column setting
	const finalGridSize = props.isMobile ? 12 : gridSize;


	const handleSubmit = (e) => {
		e.preventDefault();
		setdefaultView(!defaultView);
	}

	const openSection_child = () => {
		setnotOpen(false);
		props.openSection();
	}

	const classrich = (isitrich) ? classes.cardNoColor : classes.cardColor;
	let stylePaddingBody = (showcard) ? { padding: "0 30px 30px 30px" } : { padding: "0 30px" };

	return (
		<div className={classes.root}>
			<Container className={classes.container}>
				{isFirstSection &&
					<div className={classes.titlePage}>{textContent}</div>
				}
				{!isFirstSection &&
					<Card className={classes.cardExample + " " + classrich + ((!defaultView && showcard) ? classes.heightFix : "")}>
						<CardBody isexercise={isexercise}
							style={(props.isMobile) ? { padding: "0 0 0 30px" } : stylePaddingBody}
							className={(isitrich) ? "" : classes.notopPadding}>
							<GridContainer justifyContent="center"
								style={
									{
										alignItems: "center",
										justifyContent: "space-between",
										minHeight: "110px",
										flexWrap: (props.isMobile) ? "wrap" : "nowrap",
										flexDirection: (props.isMobile) ? "column" : "row",
										gap: (props.isMobile) ? "1rem" : undefined,
										margin: (props.isMobile) ? "20px 0" : "auto",
									}
								}>
								{/* Title of section  */}
								<GridItem style={{ width: "100%" }} spaceLeft={0}>
									<GridContainer
										alignItems="center"
										justifyContent="space-between"
										style={props.isMobile ? { width: "100%", flexDirection: "row" } : { width: "100%", flexDirection: "row", flexWrap: "nowrap" }}
									>
										<GuideCardTitle style={{ marginBottom: "0" }}>
											<GridContainer
												style={{
													alignItems: "center",
													flexDirection: "row",
													flexWrap: "nowrap",
													gap: "1rem",
													flexGrow: "1",
													width: "100%"
												}}
											>
												{/* Static width is required here. */}
												<GridItem space={0} spaceNoPadding={true}>
													<img
														src={(typeof datasav === "undefined") ? greyCheckmark : greenCheckmark}
														className={classes.dotimage2}
														width="80px"
														style={{ paddingRight: "20px" }}
														alt='Information'
													/>
												</GridItem>
												<GridItem
													md="auto"
													lg="auto"
													spaceLeft={0}
													style={{ flexGrow: "1", width: "90%", maxWidth: "100%" }}
												>
													<span>{title}</span>
												</GridItem>
											</GridContainer>
										</GuideCardTitle>
									</GridContainer>
								</GridItem>
								{/* End of title of section  */}
								{/* Button arrow to open the section  */}
								<div
									style={{ cursor: "pointer" }}
									onClick={() => openSection_child()}>
									<img
										src={arrowdown}
										style={{
											transform: (showcard) ? "rotate(-180deg)" : undefined,
											display: "block",
											margin: `${props.isMobile ? "0" : "0 auto"}`,
											flexGrow: 0,
											marginLeft: `${props.isMobile ? "-30px" : "0"}`
										}}
										alt={t("Go to next section")}
										title={t("Go to next section")}
										className={classes.iconDownArrow + ((notOpen && !props.isMobile && !showcard && (parseInt(stepn) === 1) && index === 1) ? " arrowPulseDown" : "")}
									/>
								</div>
							</GridContainer>


							<Collapse in={(isitrich) ? showcard : true} style={{ margin: `${props.isMobile ? "10px 0 20px -20px" : "auto"}` }}
								addEndListener={() => {
									// use the css transitionend event to mark the finish of a transition
									if (showcard) {
										saveresloc();
										setTimeout(() => { smoothtrans.scrollIntoView({ behavior: "smooth" }) }, 100);
									}
								}}
							>
								{/*Here is the body content*/}
								<div
									className={classes.cardBodyText + " word-break"}
									style={{ margin: `${props.isMobile ? "0px 20px" : "auto"}` }}
								>
									{textContent} {/*This is were the flipcards will come*/}
									<GridContainer justifyContent="center">
										{cards.map((card, index) => (
											<GridItem key={index} xs={12} sm={finalGridSize} md={finalGridSize} lg={finalGridSize} spaceLeft={0} >
												<FlipCard
													updateArray={handleUpdateArray}
													background={card.front.background}
													title={card.front.title}
													titleColor={card.front.title_color}
													backContent={card.back.content}
													ref={el => (cardRefs.current[index] = el)}
													direction={card.direction}
													image_cover={(props.isMobile || props.isTablet) ? false: card.front.background_image_cover}
													heightAllMobile={(props.isMobile || props.isTablet) ? card.front.heightall_mobile : false}
												/>
											</GridItem>
										))}
									</GridContainer>
								</div>

							</Collapse>
						</CardBody>
					</Card>
				}
			</Container>
		</div>
	)
}

GuideFlipCardsSection.defaultProps = {
	children: (<p>Here comes the content for this Standard Card</p>),
	title: 'Standard Card',
};

export default withTranslation()(withMyHook(withStyles(styles)(GuideFlipCardsSection)));