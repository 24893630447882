/*!
* Description: this file contains the component for
*  the pagination
* Components: Pagination
*/
import React from 'react';
import withStyles from "@mui/styles/withStyles";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PaginationArrows from "components/Pagination/PaginationArrows.js";
import Hidden from "@mui/material/Hidden";
import { Link } from "react-router-dom";
import {  ourOrange60 } from "assets/jss/incept-sustainability-variables.jsx";
import { useMediaQuery } from 'react-responsive';

//Translations
import { withTranslation } from 'react-i18next';
import endmodule from "assets/img/endmodule.png";
const styles = {
	rootbar: {
		width: '60%',
		//maxWidth: '1760px', //This is the max amount contained in the #root
		position: 'absolute',
		top: 0,
		left: '20%',
		zIndex: 200,
		fontWeight: "600",
		color: "white",
		height: "60px",

		justifyContent: "center",
		alignItems: "center",
		display: "flex",
	},
	rootbar2: {
		maxWidth: '1500px',
		position: 'sticky',
		marginRight: '10px',
		width: '100%',
		display: "flex",
	},
	root: {
		width: "100%",
		backgroundColor: "transparent !important",
		/*position: "relative"*/
	},
	/*label: {
	  fontSize: "16px",
	  fontWeight: "400",
	  opacity: "0.6",
	  width: '100%',
	  bottom: 0,
	  fontSize: '30px',
	  backgroundColor: "#ffe682",
	  color: "white",
	  borderRadius: "30px",
	  zIndex: 1000,
	  
	},
	labelafter: {
	  background:"#ffe682",
	  content: "",
	  position: "absolute",
	  top: "6px",
	  left: "195px",
	  height: "0px",
	  width: "0px",
	  borderRadius: "80px 10px",
	  border: "44px solid #545D60",
	  transform: "rotate(45deg)",
	},*/
	selected: {
		opacity: "1",
		fontSize: "16px",
		backgroundColor: "#14262c"
	},


	arrowDiv: {
		alignItems: "center",
		margin: "0 20px",
		/*"@media only screen and (max-width: 960px)": {
		  width: "18.5vh",
		},
		"@media only screen and (max-width: 660px)": {
		  width: "10vh",
		},
		"@media only screen and (min-height: 580px) and (min-width: 1024px)": {
		  marginTop: "10px",
		},
		"@media only screen and (min-height: 780px) and (min-width: 1024px)": {
		  marginTop: "0px",
		},*/
	},
	mobarrow: {
		minWidth: "140px",
		maxWidth: "168px"
	},
	navigationArrow: {
		display: "flex",
		position: "relative",
		backgroundColor: ourOrange60,
		margin: "0 10px",

		//maxWidth: "70.5px",
		maxHeight: "45px",
		maxWidth: "45px",
		borderRadius: "50%",
		alignItems: "center",
		justifyContent: "center",
		/*"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
		  width: "3.5vh",
		  height: "3.5vh",
		},
		"@media only screen and (max-width: 960px)": {
		  width: "3.5vh",
		  height: "3.5vh",
		  marginTop: "-0.2vh",
		  marginLeft: "0.4vh",
		  marginRight: "0.9vh",
		},
		"@media only screen and (max-width: 660px)": {
		  width: "3.5vh",
		  height: "3.5vh",
		  marginLeft: "0.4vh",
		  marginRight: "0.9vh",
		  marginTop: "0.2vh",
		},
		"@media only screen and (max-height: 800px) (max-width: 660px)": {
		  marginTop: "1.7vh",
		},
		"@media only screen and (min-height: 568px) and (max-height: 568px)": {
		  marginTop: "0.7vh",
		  width: "4.5vh",
		  height: "4.5vh",
		},*/
	},
	backArrow: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		//alignItems: "center"

		/*"@media only screen and (max-width: 960px)": {
		  marginLeft: "-0.88vh",
		  marginTop: "-0.85vh",
		},
		"@media only screen and (max-width: 660px)": {
		  marginLeft: "-0.8vh",
		  marginTop: "-0.63vh",
		},
		"@media only screen and (min-height: 568px) and (max-height: 568px)": {
		  marginLeft: "-0.8vh",
		  marginTop: "-0.2vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
		  marginRight: "-8px",
		},*/
	},
	forthArrow: {
		display: "flex",
		justifyContent: "center",
		/*height: "100%",*/
		width: "100%",

		/*"@media only screen and (max-width: 960px)": {
		  marginLeft: "-0.55vh",
		  marginTop: "-0.82vh",
		},
		"@media only screen and (max-width: 660px)": {
		  marginLeft: "-0.5vh",
		  marginTop: "-0.63vh",
		},
		"@media only screen and (min-height: 568px) and (max-height: 568px)": {
		  marginTop: "-0.2vh",
		},
		"@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) ": {
		  marginLeft: "-2px",
		  marginTop: "-4px",
		},*/
	},
	notMobile: {
		"@media only screen and (max-width: 960px) ": {
			display:"none"
		}
	},
	hide: {
		display: "none",
	},

	mobilePagination: {
		display: "flex",
		position: "fixed",
		bottom: "-2px",
		width: "100%",
		backgroundColor: "transparent",
		height: "50px",
		zIndex: "3",
		justifyContent: "center",
	},
	mobilePaginationInnerDiv: {
		display: "flex",
		margin: "10px",
	},

	mobileArrow1: {
		justifyContent: "center",
		marginTop: "-0.17vh",
		position: "absolute",
		right: "-10px",
	},
	mobileArrow2: {
		justifyContent: "center",
		marginTop: "-0.17vh",
		position: "absolute",
		left: "-12px",
	},
	mobnav: {
		display: "inline-flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		marginTop: "10rem",
	}
};


function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

/*
* Description: component for the pagination
*/
class Pagination extends React.Component {

	constructor(props) {
		super(props);

		const current = parseInt(props.currentPage);

		this.state = {
			currentPage: current,
			selectedArrows: Array(this.props.numPages).fill(false),
			show: false
		};
	}

	changeProgress(currentp) {
		let newSelct = Array(this.props.numPages).fill(false);
		newSelct.fill(true, 0, currentp);
		this.setState({ selectedArrows: newSelct });
	}

	componentDidMount() {
		this.changeProgress(this.props.currentPage);
	}

	componentWillReceiveProps(nextProps) {
		this.changeProgress(nextProps.currentPage);
	}

	componentDidUpdate(prevProps) {
		const [current, prevCurrent] = [parseInt(this.props.currentPage), parseInt(prevProps.currentPage)];

		if (current !== prevCurrent) {
			this.setState({
				currentPage: parseInt(this.props.currentPage)
			})
		}
	}

	showTooltip = bool => {
		this.setState({ show: bool })
	}

	handleChange = (e, newPage) => {
		this.changeProgress(newPage);
	};

	render() { 
		const { classes, basePath, t, steps, showRecapModal, isCompleted , numPages, currentPage} = this.props;
		var bgcolor = "";

		const pages = [...Array(this.props.numPages)].map((_, index) => {
			if (index === 1)
				bgcolor = "backgroundColor: '#14262c'";
			else
				bgcolor = "";

			let maxWidthobj = { color: "white", bgcolor};
			const arrowname = (this.state.selectedArrows[index]) ? "labelpaginationselprev" : "";
			let classnames_label = "labelpagination " + arrowname;
			if (index===0) 
				classnames_label = "labelpaginationfirst " + arrowname;
			else if (index=== steps.length-2) {
				classnames_label = "labelpaginationlast " + arrowname;
				maxWidthobj = { color: "white", bgcolor, maxWidth:"190px",display: "flex", alignItems: "center", border: "2px solid red"};
			}
			// Disable the pagination if the step is not completed
			const shouldDisable = !isCompleted && index > this.state.currentPage-1;
			const showFlag = index  === numPages -1;
			return (
					<React.Fragment key={index}>
						<BottomNavigationAction
								classes={{
									label: classnames_label,
									selected: "labelpaginationsel",
									
								}}
								disabled={shouldDisable}
								label={<span>{index + 1} </span>} 
								style={{color: "white", width: "100%"}} 
								value={index + 1}
								showLabel={true}
								component={Link} 
								to={(this.props.iskb) ? `${basePath}/${index + 1}/${this.props.typemode}/`:`${basePath}/${index + 1}/act/`} 
							/>
							{/* This css might not be the best but i dont want to spend too much time on this for now 😁 */}
							{ showFlag && <img src={endmodule} width={"16px"} height={"16px"} alt='Finish flag' style={{marginLeft: "-60px", marginTop: "20px", zIndex: 1000}}/>}
					</React.Fragment>
					)
		});


		if (this.state.currentPage !== 0) {
			return (
				<div className={classes.rootbar} style={{paddingTop: this.props.isMobile ? "" : "3.1rem",}}>
					{/* If the numpages is 1, dont show it (The opacity is the hack 😁) */}
					<div className={classes.rootbar2} style = {{opacity: numPages <= 1 ? "0" : "1" }} >  
						{/*For desktop version*/}
						<Hidden mdDown>
							<BottomNavigation
								value={this.state.currentPage}
								onChange={this.handleChange}
								className={classes.root}
							>
								{pages}
							</BottomNavigation>
						</Hidden>
						{/*For mobile version*/}
						<Hidden mdUp>
							<div className={classes.mobnav}>
								<div className={classes.navigationArrow}>
									<div className={classes.backArrow}>
										<PaginationArrows
											numPages={pages.length}
											currentPage={this.state.currentPage}
											type="back"
											basePath={basePath}
										/>
									</div>
								</div>

								<div className={classes.mobarrow}
									style={{ color: "white"}}>
									<span className={"labelpagination labelpaginationselprev"}>
										<span className={"labelpaginationsel"}>
											{this.state.currentPage} / {this.props.numPages}
										</span>
									</span>
								</div>

								<div className={classes.navigationArrow} style={{ margin: "0 0 0 40px" }}>
									<div className={classes.forthArrow}>
										<PaginationArrows
											numPages={pages.length}
											currentPage={this.state.currentPage}
											type="forth"
											basePath={basePath}
											typemode={this.props.typemode}
											onClick={(this.state.currentPage === pages.length) ? () => showRecapModal(this.props.iskb) : function () { }}
										/>
									</div>
								</div>
							</div>
						</Hidden>
					</div>
				</div>
			);
		}
		else {
			return (
				<span>	</span>
			);
		}
	}

}

Pagination.defaultProps = {
	onChange: () => { }
};

export default withTranslation()(withMyHook(withStyles(styles)(Pagination)));