import React from "react";
import jwt from "jwt-decode";
import NewAppIFrame from "components/NewAppIFrameViewer";
import clientConfig from "client-config.js";
import { useSharedTokenInfo } from "context/auth_context/TokenContext";

/**
 * Since it is extremely daunting to work in this React app I used normal CSS directly on the HTML elements
 * Also, I did not use translation at all, since this feature is ONLY for Swedbank and it will be in Swedish. I know it sucks but the whole app sucks, hence ...
 */

const LoanLandingPage = () => {

  return (
    <NewAppIFrame
      iFramePagePath={`loan`}
      title="Loan Application"/>
  );
};

export default LoanLandingPage;
