import React, { useState, useEffect, useRef, forwardRef } from "react";
import FlipCardFront from "./FlipCardFront";
import FlipCardBack from "./FlipCardBack";
import { FlipAnimationCSS } from "./FlipAnimation";

/**
 * FlipCard component that displays a card with a front and back side that can be flipped.
 * @param {Object} props - The component props.
 * @param {string} props.background - The background color of the front side of the card.
 * @param {string} props.title - The title text to display on the front side of the card.
 * @param {string} props.titleColor - The color of the title text on the front side of the card.
 * @param {ReactNode} props.backContent - The content to display on the back side of the card.
 * @param {number} props.heightAllMobile - The height of the card on mobile devices.
 * @param {function} props.updateArray - A function to update an array with the maximum height of the card.
 * @param {string} props.direction - The direction in which the card should flip when clicked.
 * @param {Object} ref - A ref object to reference the component.
 * @returns {JSX.Element} - The rendered FlipCard component.
 */
const FlipCard = forwardRef(({ background, title, titleColor, backContent, image_cover, heightAllMobile, updateArray, direction }, ref) => {
    const frontCardRef = useRef(null);
    const backCardRef = useRef(null);
    const [cardOpen, setcardOpen] = useState(true);
    const [flipDirection, setFlipDirection] = useState(direction);

    useEffect(() => {
        const frontHeight = frontCardRef.current ? frontCardRef.current.offsetHeight : 0;
        const backHeight = backCardRef.current ? backCardRef.current.offsetHeight : 0;
        const maxSide = Math.max(frontHeight, backHeight);
        updateArray(maxSide);
    }, []);

    /**
     * Handles the image load event and updates the maximum height of the card.
     */
    const handleImageLoad = () => {
        const frontHeight = frontCardRef.current ? frontCardRef.current.offsetHeight : 0;
        const backHeight = backCardRef.current ? backCardRef.current.offsetHeight : 0;
        const maxSide = Math.max(frontHeight, backHeight);
        updateArray(maxSide);
    };

    /**
     * Handles the click event to flip the card.
     */
    const handleFlipClick = () => {
        setcardOpen(!cardOpen);
    };

    /**
     * Gets the flip animation style based on the current state of the card.
     * @returns {Object} - The flip animation style object.
     */
    const getFlipStyle = () => {
        if (cardOpen) return FlipAnimationCSS.flipCard;
        switch (flipDirection) {
            case 'right':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedRight };
            case 'left':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedLeft };
            case 'up':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedUp };
            case 'down':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedDown };
            case 'upRight':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedUpRight };
            case 'upLeft':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedUpLeft };
            case 'downRight':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedDownRight };
            case 'diagonal-right':
                return { ...FlipAnimationCSS.flipCard, ...FlipAnimationCSS.flippedDownLeft };
            default:
                return FlipAnimationCSS.flipCard;
        }
    };

    // Get backcard flip adjustment based on the direction of the flip

    const getBackCardFlipStyle = () => {
        switch (flipDirection) {
            case 'right':
                FlipAnimationCSS.flipCardBack.transform = 'rotateY(180deg)';
                return {...FlipAnimationCSS.flipCardBack}
            case 'left':
                FlipAnimationCSS.flipCardBack.transform = 'rotateY(-180deg)';
                return {...FlipAnimationCSS.flipCardBack}
            case 'up':
                FlipAnimationCSS.flipCardBack.transform = 'rotateX(-180deg)';
                return {...FlipAnimationCSS.flipCardBack}
            case 'down':
                FlipAnimationCSS.flipCardBack.transform = 'rotateX(180deg)';
                return {...FlipAnimationCSS.flipCardBack}
            case 'diagonal-right':
                FlipAnimationCSS.flipCardBack.transform = 'rotateZ(-180deg) rotateY(180deg)';
                return {...FlipAnimationCSS.flipCardBack}
            case 'diagonal-left':
                FlipAnimationCSS.flipCardBack.transform = 'rotateZ(-180deg) rotateY(-180deg)';
                return {...FlipAnimationCSS.flipCardBack}
            default:
                return FlipAnimationCSS.flipCardBack;
        }
    };

        

    return (
        <div
            ref={ref}
            style={{ ...getFlipStyle(), alignItems: cardOpen ? 'center' : 'flex-start' }}
            onClick={handleFlipClick}
        >
            <div style={FlipAnimationCSS.flipCardFront}>
                <FlipCardFront 
                ref={frontCardRef} 
                background={background} 
                title={title} 
                titleColor={titleColor}
                heightAllMobile={heightAllMobile} 
				image_cover={image_cover}
                onImageLoad={handleImageLoad} />
            </div>
            <div style={{...getBackCardFlipStyle()}}>
                <FlipCardBack ref={backCardRef} backContent={backContent} isCardOpen={cardOpen} />
            </div>
        </div>
    );
});

export default FlipCard;
