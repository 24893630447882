import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";

const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "borderColor",
})(({ borderRadius = "20px", borderColor = "grey" }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: borderColor,
    borderRadius: borderRadius,
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "grey",
  },
}));

const TextArea = ({ borderRadius, borderColor, variant, ...other }) => {
  return <CustomTextField multiline minRows={4} fullWidth variant={variant ?? 'outlined'} borderRadius={borderRadius} borderColor={borderColor} {...other} />;
};

export default TextArea;
