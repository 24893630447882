import { useState } from 'react';
import FilledStar from "assets/img/filled_star.svg";
import EmptyStar from "assets/img/empty_star.svg";
import { withTranslation } from 'react-i18next';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  },
  starsContainer: {
    display: 'flex',
    gap: '4px'
  },
  starButton: {
    padding: '6px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
  },
  starButtonHover: {
    transform: 'scale(1.1)'
  },
  label: {
    fontSize: '14px',
    color: '#666',
    minHeight: '24px',
    margin: 0,
    textAlign: 'center',
    width: '100%'
  },
  starIcon: {
    width: '48px',
    height: '48px',
    transition: 'all 0.2s ease',

    "@media (max-width: 600px)": {
      width: "32px",
    },
  },

  
};

const StarRating = ({ defaultRating, onChange, t}) => {
  const [rating, setRating] = useState(defaultRating);
  const [hover, setHover] = useState(0);


  const handleClick = (value) => {
    setRating(value);
    onChange?.(value);
  };

  const labels = {
    0: '',
    1: t('Below expectations'),
    2: t('Somewhat helpful'),
    3: t('Satisfactory'),
    4: t('Valuable'),
    5: t('Recommendable')
  };

  return (
    <div style={styles.container}>
      <div style={styles.starsContainer}>
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            style={{
              ...styles.starButton,
              ...(hover === star ? styles.starButtonHover : {})
            }}
            onMouseEnter={() => setHover(star)}
            onMouseLeave={() => setHover(0)}
            onClick={() => handleClick(star)}
          >
            <img
              src={star <= (hover || rating) ? FilledStar : EmptyStar}
              alt="star"
              style={styles.starIcon}
            />
          </button>
        ))}
      </div>
      {
        <p style={styles.label}>
          {labels[hover || rating]}
        </p>
      }
    </div>
  );
};

export default withTranslation()(StarRating) ;
