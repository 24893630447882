/*!
 * Description: this file contains the component for
 *  the type of exercise DragAndDrop, ex. guide 1, step 2, "Exercise: Rank Overshoot Days"
 * Components: DragAndDrop
 */
import React from "react";
import ReactHtmlParser from "react-html-parser";

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourDarkGreen,ourMediumGreen,standardRadious } from "assets/jss/incept-sustainability-variables";

// Style components
import Grid from "@mui/material/Grid";

import Container from "@mui/material/Container";
// Effects
import Collapse from "@mui/material/Collapse";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

//Merger of classes
import clsx from 'clsx';

import Select from "components/Exercise/Select.jsx";
import checked from 'assets/img/checkedWhiteBg.png';
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
		flex: "row",
		marginTop: "50px"
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginBottom: "20px"
	},
	dndCardBody: {
		fontSize: "20px",
	},
	droppable: {
		height: "100%",
		backgroundColor: ourMediumGreen,	
		color: ourDarkGreen,
		borderRadius: standardRadious,
		"&:hover": {
			cursor: "grab",
		},
		"&:active": {
			cursor: "grabbing",
		},
	},
	droppableOnDrop: {
		height: "auto",
		padding: "10px",
		border: `2px solid ${ourDarkGreen}`,
		fontWeight: 'bold',
		borderRadius: standardRadious,
	},
	labelForDroppable: {
		height: "45px",
		width: "45px",
		borderRadius: "50%",
		color: "white",	
		backgroundColor: ourDarkGreen,
		textAlign: "center",
		padding: "8px 8px 8px 8px",
		fontWeight: "bold",
		margin: "2px",
		fontSize: "13px",
	},
	draggable: {
		padding: "10px",
		border: `2px solid ${ourDarkGreen}`,	
		color: ourDarkGreen,
		fontWeight: 'bold',
		borderRadius: standardRadious,
		"&:hover": {
			cursor: "grab",
			cursor: "-moz-grab",
			cursor: "-webkit-grab",
		},
		"&:active": {
			cursor: "grabbing",
			cursor: "-moz-grabbing",
			cursor: "-webkit-grabbing",
		},
	},
	draggableLi: {
		margin: "10px 0",
	},
	list: {
		listStyleType: "none",
		padding: "0",
		marginTop: "10px",
	},
	textf2: {
		marginTop: "10px",
	},
	buttonContainer: {
		textAlign: "end",	
		paddingRight: "0"
	},
	buttonContainerAnswers: {
		textAlign: "end",
		marginBottom: "30px",
		paddingRight: "0",
		"@media only screen and (max-width: 960px)": {
			margin: "0"
		},
	},
	buttonAnswer: {
		color: "#fff",
		borderRadius: "3rem",
		textTransform: "none",
		backgroundColor: ourDarkGreen,
		width: "160px",
		height: "25px",
		"&:hover, &:active, &:focus": {
			filter: "brightness(85%)",
			backgroundColor: ourDarkGreen,
		},
	},
	textarea: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'white',
			},
		},
	},
	answercontainer: {
		"@media only screen and (max-width: 600px)": {
			padding: "0"
		},
	}


};

const Question = withStyles(styles)((props) => {
	const { classes, isDropped, ...rest } = props;
	const droppedStyle = isDropped ? styles.droppableOnDrop : {};

	return (
		<div draggable className={classes.droppable} style={droppedStyle} {...rest}>
			{props.children}
		</div>
	);
});

const QuestionGrid = withStyles(styles)(({ classes, question, children }) => (
	<Grid container spacing={2} style={{margin:"-8px"}}>
		<Grid item style={{padding:"8px"}}>
			<div className={classes.labelForDroppable}>{question}</div>
		</Grid>
		<Grid item xs style={{padding:"8px"}}>
			{children}
		</Grid>
	</Grid>
));

const RealAnswer = withTranslation()(withStyles(styles)(
	({ classes, onClick, answerImg, showAnswer, t }) => (
		<React.Fragment>
			<Container className={classes.buttonContainerAnswers}>
				<ActionButton
					style={{width:"auto"}}
					onClick={onClick}
					type="submit"
				>
					{t('See correct answer')}
				</ActionButton>
			</Container>
			<Container className={classes.answercontainer}>
				<Collapse in={showAnswer}>{ReactHtmlParser(ReactHtmlParser(answerImg))}</Collapse>
			</Container>
		</React.Fragment>
	)
));

const QuestionsContainer = withStyles(styles)(
	({ classes, questionsArray, children }) => {
		const questions = questionsArray.map((question, index) => (
			<li key={index}>
				<QuestionGrid question={question}>{children(index,question)}</QuestionGrid>
			</li>
		));

		return <ul className={classes.list}>{questions}</ul>;
	}
);

const Answer = withStyles(styles)(({ classes, children, ...rest }) => {
	return (
		<div draggable className={classes.draggable} {...rest}>
			{children}
		</div>
	);
});

const AnswersContainer = withStyles(styles)(
	({ classes, answersArray, children }) => {
		const answers = answersArray.map((answer, index) => {
			if (!answer) {
				return "";
			}
			const { text } = answer;
			return (
				<li key={index} className={classes.draggableLi}>
					{children(index, text)}
				</li>
			);
		});

		return <ul className={classes.list}>{answers}</ul>;
	}
);

class DragAndDropRanking extends React.Component {
	//Should  not exist more questions than answers

	// We have two arrays: paired and unpaired.
	// Unpaired gets filled by all answers (options) at first.
	// Questions and answers are univocally identified by its index inside props array.
	// This way, unpaired[index] means answer in the index position is unpaired, unless its value is null.
	// paired[index] means questions in the index position is paired if it has a value.
	constructor(props) {
		super(props);
		this.state = {
			answers: [
				...this.props.exercise.answers.map((value, index) => {
					return { text: value.text, pairedWith: -1, statement_id: value.statement_id };
				}),
			],
			paired: [...Array(this.props.exercise.questions.length)],
			unpaired: JSON.parse(JSON.stringify(this.props.exercise.answers)), //Deep copy of array
			nlist: this.props.exercise.questions.length - 1,
			answer_to_save: [],
			showAnswer: false,
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		};
		if (typeof this.props.datasav2 !== "undefined" && this.props.datasav2["content"].length>0) {
			var aux = this.clone(this.props.datasav2["content"]);
			var aux = aux.filter(elements => {
				return (elements != null && elements !== undefined && elements !== "");
			   });
			for (var index2 = 0; index2 < aux.length; index2++) {
				if (aux[index2] === "") {
					aux.splice(index2, 1);
                    this.state.answer_to_save.push("");
					//break;
				}
                this.state.answer_to_save.push(aux[index2]);
			}
			for (index2 = 0; index2 < aux.length; index2++) {
				for (var index3 = 0; index3 < this.state.answers.length; index3++) {
					if (aux[index2].statement_id===this.state.answers[index3].statement_id)
						aux[index2].text = this.state.answers[index3].text;
				}
			}
			this.state.answers = aux;
		}
	}

	clone(obj) {
		if (null === obj || "object" !== typeof obj) return obj;
		var copy = obj.constructor();
		for (var attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
		}
		return copy;
	}

	onDropBack = (event) => {
		const questionIndex = event.dataTransfer.getData("questionIndex");
        //const ranking = event.dataTransfer.getData("ranking");
		const data_to_save_1 = [];
		const data_to_save_2 = [];
		let data_to_save = [];
		
		this.setState((prevState) => {
			const newAnswers = prevState.answers.map(
				({ pairedWith, value, ...rest }, index) => {
					if (pairedWith === parseInt(questionIndex)) {
						data_to_save_2.push({ ...rest, pairedWith: -1, value: -1 });
						return { ...rest, pairedWith: -1, value: -1 };
					}
					else {
						if (pairedWith === -1) {
							data_to_save_2.push({ ...rest, pairedWith: pairedWith, value: value });
						}
						else
							data_to_save_1.push({ ...rest, pairedWith: pairedWith, value: value });
					}
					return { ...rest, pairedWith: pairedWith, value: value  };
				}
			);
			data_to_save = [...data_to_save_1, "", ...data_to_save_2];
			/*this.props.save2("DragDropTwo_standard", data_to_save, this.props.inkey,
				this.props.exercise_id);*/

			return {
				...prevState,
				answers: newAnswers,
				answer_to_save: data_to_save,
				submittedanswer: false
			};
		});

	};

	onDragOverBack = (event) => {
		event.preventDefault();
	};

	onDragStartBack = (event, questionIndex, ranking) => {
		event.dataTransfer.setData("questionIndex", questionIndex.toString());
        event.dataTransfer.setData("rankingC", ranking);
	};

	onDragStart = (event, answerOrder) => {
		event.dataTransfer.setData("answerOrder", answerOrder.toString());
	};

	onDragOver = (event) => {
		event.preventDefault();
	};

	onDrop = (event, questionOrder, rankingT) => {


		let answerOrder = event.dataTransfer.getData("answerOrder");

		if (!answerOrder) {
			let questionIndex = event.dataTransfer.getData("questionIndex");
			answerOrder = this.state.answers.findIndex(
				({ pairedWith }) => pairedWith === parseInt(questionIndex)
			);
		}

		let atLeastOnePaired = this.state.answers.findIndex(
			({ pairedWith }) => pairedWith === parseInt(questionOrder)
		);

		if (atLeastOnePaired > -1) {
			return;
		}
		
		this.setState((prevState) => {
			const data_to_save_1 = [];
			const data_to_save_2 = [];
			let data_to_save = [];
			const newAnswers = prevState.answers.map(
				({ pairedWith, value, text, ...rest }, index) => {
					//for saving
					if (index !== parseInt(answerOrder)) {
						if (pairedWith === -1)
							data_to_save_2.push({ ...rest, text, pairedWith: pairedWith, value: value});
						else
							data_to_save_1.push({ ...rest, text, pairedWith: pairedWith, value: value });
					}
					//
					else {
						data_to_save_1.push({ ...rest, text, pairedWith: questionOrder, value: rankingT });
						return { ...rest, text, pairedWith: questionOrder, value: rankingT };
					}

					return { ...rest, text, pairedWith: pairedWith, value: value };
				}
			);
			data_to_save = [...data_to_save_1, "", ...data_to_save_2];
			/*this.props.save2("DragDropTwo_standard", data_to_save, this.props.inkey,
				this.props.exercise_id);*/
			return {
				answers: newAnswers,
				nlist: this.state.nlist - 1,
				answer_to_save: data_to_save,
				submittedanswer: false
			};
		});
	};

	//This solves the complexity behind saving state to backend:
	// We just need to listen to changes in state, and split paired and unpaired answers
	/*componentDidUpdate() {
		const unpaired = this.state.answers.filter(({ text, pairedWith }) => pairedWith === -1);
		const paired = this.state.answers.filter(({ text, pairedWith }) => pairedWith !== -1);

		//const data_to_save = [...paired, "", ...unpaired];
		//this.props.save2("DragDropTwo_standard", data_to_save, this.props.inkey, this.props.exercise_id);
	}*/

	handleAnswer(e) {
		e.preventDefault();
		this.setState({ showAnswer: !this.state.showAnswer });
	}

	handleSubmit(e) {
		e.preventDefault();
		//if (this.state.answer_to_save.length>0) {
			this.setState({ submittedanswer: true });
            console.log("answer ro save");
            console.log(this.state.answer_to_save);
			this.props.save2("DragDropTwo_ranking", this.state.answer_to_save, this.props.inkey,
					this.props.exercise_id);
		//}
	}

	_getAnswerByPairedWith(questionIndex) {
		const allPossiblePaired = this.state.answers.filter(
			({ pairedWith }, index) => pairedWith === questionIndex
		);
		if (allPossiblePaired.length > 0) {
			return allPossiblePaired[0];
		}
		return false;
	}

	_isAnswerPairedWith(questionIndex) {
		const possiblePaired = this._getAnswerByPairedWith(questionIndex);
		return !!possiblePaired;
	}

	handleSelectChange(event, questionIndex, rankingT) {
		const answerIndex = event.target.value;
		const data_to_save_1 = [];
		const data_to_save_2 = [];
		let data_to_save = [];
		this.setState((prevState) => {
			const newAnswers = prevState.answers.map(
				({ pairedWith, value, ...rest }, index) => {
					//for saving
					if (index !== parseInt(answerIndex)) {
						if (pairedWith === -1)
							data_to_save_2.push({ ...rest, pairedWith: pairedWith, value: value });
						else if (answerIndex === -1) {
							data_to_save_2.push({ ...rest, pairedWith: -1, value: -1 });
						}
						else
							data_to_save_1.push({ ...rest, pairedWith: pairedWith, value: value });
					}
					//
					if (index === parseInt(answerIndex)) {
						data_to_save_1.push({ ...rest, pairedWith: questionIndex, value: rankingT });
						return { ...rest, pairedWith: questionIndex, value: rankingT  };
					} else if (pairedWith === parseInt(questionIndex)) {
						return { ...rest, pairedWith: -1, value: -1  };
					}
					return { ...rest, pairedWith: pairedWith, value: value  };
				}
			);
			data_to_save = [...data_to_save_1, "", ...data_to_save_2];
			/*this.props.save2("DragDropTwo_standard", data_to_save, this.props.inkey,
				this.props.exercise_id);*/
			return {
				answers: newAnswers,
				submittedanswer: false,
				answer_to_save: data_to_save
			}
		});

	}

	render() {
		const { classes, exercise, t } = this.props;
		const { statement, questions, answerimg } = exercise;
		//const touch = window.matchMedia('(hover: none)').matches;
		//This works on andrid as well:
		const touch = window.matchMedia('(pointer: coarse)').matches;
		const select =
			<Grid item xs={12}>
				<div style={{ margin: '0 auto', maxWidth: '500px' }}>
					<Select
						questions={questions}
						answers={this.state.answers}
						onChange={(e, q, v) => this.handleSelectChange(e, q, v)}
					/>
				</div>
			</Grid>;

		const dragAndDrop = (
			<Grid container spacing={6}>
				<Grid item xs={12} sm={6}>
					<QuestionsContainer questionsArray={questions}>
						{(questionIndex,ranking) => {
                            return(
							<Question
								isDropped={this._isAnswerPairedWith(questionIndex)}
								onDrop={(e) => this.onDrop(e, questionIndex, ranking)}
								onDragOver={(e) => this.onDragOver(e)}
								onDragStart={(e) =>
									this.onDragStartBack(e, questionIndex, ranking)
								}
							>
								{this._getAnswerByPairedWith(questionIndex).text}
							</Question>
                            );
						}}
					</QuestionsContainer>
				</Grid>
				<Grid
					onDrop={(e) => this.onDropBack(e)}
					onDragOver={(e) => this.onDragOverBack(e)}
					item
					xs
					sm={6}
				>
					<AnswersContainer
						answersArray={this.state.answers}
					>
						{(answerIndex, answer) => {
							return (this.state.answers[answerIndex].pairedWith <= -1) &&
								<Answer
									onDragStart={(e) => this.onDragStart(e, answerIndex)}
								>
									{answer}
								</Answer>
						}
						}
					</AnswersContainer>
				</Grid>
			</Grid>
		);
		return (
			<div className={classes.root}>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						{ReactHtmlParser(ReactHtmlParser(statement))}
					</Grid>
					{touch ? select : dragAndDrop}
					<Grid item xs={12} md={12} className={clsx("paddingExercise")} >
						<div className={classes.statusContainer}>
						{this.state.submittedanswer && <p>{t("Great! Your answer has been saved.")}</p>}
						</div>
						<Container className={classes.buttonContainer}>
							<ActionButton
								style={{width:"auto"}}
								type="submit"
								centered={true}
								onClick={(e) => this.handleSubmit(e)}
							>
								{t('Submit')}
							</ActionButton>
						</Container>
						{answerimg !== "" && (
							<RealAnswer
								answerImg={answerimg}
								showAnswer={this.state.showAnswer}
								onClick={(e) => this.handleAnswer(e)}
							/>
						)}

					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(DragAndDropRanking));
