import React from 'react';
import { useLocation } from 'react-router-dom'; // Import hooks from react-router-dom
import NewAppIFrame from 'components/NewAppIFrameViewer';

function Assessments({ iFramePagePath: propIFramePagePath }) {
  const location = useLocation(); // Get the current location

  // Determine iFramePagePath
  let iFramePagePath = propIFramePagePath;

  if (!iFramePagePath) {
    // If no prop is provided, construct the path based on the current URL
    iFramePagePath = location.pathname.replace('/admin', ''); // Adjust as needed
  }

  return (
    <NewAppIFrame
      iFramePagePath={iFramePagePath}
      title="Assessments"
    />
  );
}

export default Assessments;