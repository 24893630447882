// Interface
import {FilterClass} from "./interfaces/FilterClass";

// Base filter
import {IdeasAndCommentsFilter} from "./BaseFilters/IdeasAndCommentsFilter";
import {ResetFilter} from "./BaseFilters/ResetFilter";

// Imports ---
import {ClearFilter} from "./Filters/ClearFilter";

class FilterFactory {

    public static createFilter<T extends string | number, K>(type: string): IdeasAndCommentsFilter<T, K> | ResetFilter {
        const filterInstance = FilterClass.getFilterType(type);
        if (!filterInstance) throw new Error(`Filter type ${type} not supported.`);

        if (filterInstance === ClearFilter) {
            // Type assertion to let TypeScript know filterInstance is of type ClearFilter
            return (filterInstance as typeof ClearFilter).createFilter();
        }
        return filterInstance.createFilter();
    }

    public static getFilterTypes(): Record<string, FilterClass<any, any>> {
        return FilterClass.getAllFilterTypes();
    }
}


export { FilterFactory };
