/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import withStyles from "@mui/styles/withStyles";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { n_comp_answer_export,n_comp_cust, isAuthenticated,ourBlue } from "assets/jss/incept-sustainability-variables.jsx";

import FreeTextCard from "views/Analytics/AnalyticsCompany/FreeTextCard.jsx";
import Form from "react-bootstrap/Form";
import ActionButton from "components/CustomButtons/ActionButton";
import axios from "axios";
import loadinggif from "assets/img/loading.gif";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { IconButton } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import icon7 from "assets/img/icon7.png";
import { withTranslation } from 'react-i18next';
import PageWrapper from 'components/PageWrapper/PageWrapper';

const useStyles = () => ({
	cardPaddingRight: () => ({
		marginRight: "30px",
		"@media only screen and (max-width: 960px)": {
			marginRight: "15px",
		}
	}),
	modalBox: {
		"& .modal-content": {
			borderRadius: "2rem",
		},
	},
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right",
	},
	showElement: {
		display:"block"
	},
	hideElement: {
		display:"none !important"
	},
});

function AnalyticsExercise(props) {
	const {classes, history, location ,content, t} = props;
	const [departments, setDepartments] = useState([]);
	const [answersCompany, setCompanyAnswers] = useState([]);
	const [showAboutModal, setshowAboutModal] = useState(false);
	const [textAbout, settextAbout] = useState(<div></div>);
	const [listchecked, setlistchecked] = useState([]);
	const [loadingfirst, setloadingfirst] = useState(true);
	const [hideExported, sethideExported] = useState(false);
	const [exportAnswerM, setexportAnswerM] = useState(false);
	const [exportAnswerB, setexportAnswerB] = useState(false);
	const [exportingComment, setexportingComment] = useState(false);
	const [nonCheckedideas, setnonCheckedideas] = useState(true);
	const [actInfo, setInfoAct] = useState({title: "",nanswers:0, exerciseid:"", 
											nempty:0, module: "", description: "",pempty:0,nexported:0, type:""});

	const searchParams = new URLSearchParams(location.search);
	let filter = {};
	if (searchParams.has("filter")) {
		filter = JSON.parse(searchParams.get("filter"));
	}
	const [filterState, setFilterState] = useState(filter);

	useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		if (loadingfirst) {
			const { match } = props;
			const { exerciseid } = match.params;
			setInfoAct({
				...actInfo,
				exerciseid: exerciseid
			});
			getAnswers(exerciseid);
		}
	  }, []);

	//Get ideas from backend
	const getAnswers = async (exerciseid) => {
		if (!isAuthenticated()) {
			props.isExpired();
		}
		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		const insdata = {
			departmentid: filterState.department,
			countryid: JSON.stringify(filterState.country)
		};
		axios
			.get(n_comp_cust+exerciseid , { headers: { Authorization: authstr },params: insdata })
			.then((response) => {
				setCompanyAnswers(response.data.answers.values);
				setlistchecked(new Array(response.data.answers.values.length).fill(false));
				setInfoAct({ ...actInfo,
					title: response.data.title_field,
					nanswers: response.data.answers.nvalues,
					nempty: response.data.answers.nempty,
					nexported: response.data.answers.nexported,
					module: response.data.module,
					description: response.data.description,
					pempty: response.data.answers.pempty,
					type: response.data.type
				});
				setDepartments(JSON.parse(response.data.departments));
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setloadingfirst(false);
			});
	}

	const showHideModal = (typeActivity) => {
		try {
			setshowAboutModal(!showAboutModal);
			let text = (<div>
				<b>Answers: </b>all employees that submitted an answer (including empty submissions).<br></br>
				<b>Empty submissions: </b>all employees that submitted empty answers.<br></br>
				<b>Non empty submissions: </b>(Answers - Empty submissions)<br></br></div>);
			if (typeActivity === "pie") {
				text = (<div>{text}<h4></h4><p>
					Each half pie chart and bar chart shows the percentage of how many employees selected that option,
					of all the employees that did not submit empty.<br></br>
					Have in mind that some activities require users to select more than 1 option. </p>
				</div>)
			}
			else if (typeActivity === "bar") {
				text = (<div>{text}<h4></h4><p>
					Each bar chart shows the percentage of employees that dragged and dropped an option to right or left box
					(green and yellow legends).<br></br>
					The grey area of the bar represents the percentage of employees that did not choose this option at all. </p>
				</div>)
			}
			settextAbout(text);
		}
		catch (error) {
			throw new Error("Component: AnalyticsCompany Card - showHideModal");
		}
	};

	function handleChangeCheckbox(itemid,indexComment) {
		let allfalse = true;
		let sa = listchecked.map((checkedValue, index) => {
			if (index===indexComment) {
				if (!checkedValue && allfalse)
					allfalse = false;
				return !checkedValue;
			}
			if (checkedValue && allfalse)
				allfalse = false;
			return checkedValue;	
		});	
		setnonCheckedideas(allfalse);	
		setlistchecked(sa);
	}

	const exportComment = () => {
		if (!isAuthenticated()) {
			props.isExpired();
		}
		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		setexportingComment(true);
		setexportAnswerB(false);

		const commentsids = answersCompany
			.filter((r,index) => { return listchecked[index]; })
			.map((m,index) => { return {answerid: m.answerid}}); 

		const insdata = {
			answers: JSON.stringify(commentsids)
		};
		axios
			.post(n_comp_answer_export, insdata, {
					headers: { Authorization: authstr },
			})
			.then((response) => {
				//change boolean element of answers to true for the exported answers
				let neanswersArray = answersCompany.map((answer, index) => {
					if (listchecked[index]) {
						let { isexported, ...restItem } = answer;	
						return {...restItem, isexported: true};
					}
					return answer;	
				});	
				setCompanyAnswers(neanswersArray);
				let {nexported, ...restItem} = actInfo;
				setInfoAct({
					nexported: actInfo.nexported+commentsids.length,
					...restItem
				});
				setlistchecked(new Array(answersCompany.length).fill(false));
				setexportingComment(false);
				setexportAnswerB(true);
				setexportAnswerM(false);
			})
			.catch((err) => {
				console.log(err);
				setexportingComment(false);
				setexportAnswerM(false);
			});
	}

	const exportOpenModal = (e) => {
		setexportAnswerM(true);
		setexportAnswerB(false);
	};

	const exportCloseModal = (e) => {
		setexportAnswerM(false);
	};

	const hideExportedF = (e) => {
		sethideExported(!hideExported);
	};

	if(loadingfirst) {
		return (
			<PageWrapper activeOverlay={loadingfirst}></PageWrapper>
		)
	}

	if (answersCompany.length>0) {
		let countrynames = "";
		if (filterState.country.length>0) {
			countrynames = filterState.country.map((countr) => {
				return <span key={countr.id}>{countr.name+" "}</span>
			});
		}
		return (
            <div className={classes.containerAnalytics} id="testscroll">
				<h2 className={classes.titleHeader}>
					{'Company analytics'}
				</h2>
				{/*<div className={classes.cardPaddingRight} style={{margin:"0 0 50px 0"}}>
					<AnalyticsCompany_Header 	
					departments={departments}	
					/>
				</div>*/}
				<div style={{margin:"0 0 50px 0"}}>
					<GridContainer>
						<div className={classes.cardMargin}><FreeTextCard
							title={actInfo.title}
							data={answersCompany}
							nitems={actInfo.nanswers}
							listchecked={listchecked}
							exerciseid={actInfo.exerciseid}
							showHideModal={showHideModal}
							showButton={false}
							nonCheckedideas={nonCheckedideas}
							handleChangeCheckbox={handleChangeCheckbox}
							exportOpenModal={exportOpenModal}
							hideExportedF={hideExportedF}
							hideExportedB={hideExported}
							generalPage={false}
						>
						<div>
							<span style={{fontWeight:"bold"}}>{"Module: "}</span>
							<span>{actInfo.module}</span>
						</div>
						<div>
							<span style={{fontWeight:"bold"}}>{"Description: "}</span>
							<span>{actInfo.description}</span>
						</div>
						<div style={{marginTop:"20px"}}>
							<span style={{fontWeight:"bold"}}>{"Answers: "}</span>
							<span style={{color:ourBlue}}>
								{(actInfo.nanswers>0) ? actInfo.nanswers:actInfo.nempty}
							</span>
						</div>
						<div>
							{actInfo.type!=="multi-choice" && <span><span style={{fontWeight:"bold"}}>{"Empty submissions: "}</span>
							<span style={{color:ourBlue}}>
								{actInfo.nempty+"/"+((actInfo.nanswers>0) ? actInfo.nanswers:actInfo.nempty)+" "}
							</span>(<span style={{color:ourBlue}}>{actInfo.pempty+"%"}</span>)</span>}
						</div>
						<div>
							<span style={{fontWeight:"bold"}}>{'Exported to "Ideas and Comments": '}</span>
							<span style={{color:ourBlue}}>
								{actInfo.nexported}
							</span>
						</div>
						{(filterState.department!==0 || filterState.country.length>0) && <div style={{marginTop:"30px"}}>
							<b><p><span>{"This results are from"}</span>
							<span style={{color:ourBlue}}>{(filterState.department!==0 ? " "+departments[parseInt(filterState.department)]:"")}</span>
							<span style={{color:ourBlue}}>
								{" "}
								{countrynames}
							</span>
							</p></b>
						</div>}
						</FreeTextCard></div>
					</GridContainer>
				</div>
				<Modal
					id="AnalyticsCard3"
					show={showAboutModal}
					onHide={() => setshowAboutModal(false)}
					centered
					size="lg"
					className={classes.modalBox}
				>
					<Modal.Body>
						<IconButton
                            onClick={() => setshowAboutModal(false)}
                            className={classes.modalCancelButton}
                            size="large">
							<img src={icon7} alt="cancel icon" width="40" height="40" />
						</IconButton>
						<div style={{ margin: "20px" }}>
							<h4 style={{ marginBottom: "15px" }}>
								{"How are the stats calculated?"}
							</h4>
							<GridContainer>
								<GridItem space={0} style={{ paddingRight: "20px" }}>
									{textAbout}
								</GridItem>
							</GridContainer>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					id="4"
					show={exportAnswerM}
					onHide={() => {
						setexportAnswerM(false);
					}}
					centered
					size="md"
					className={classes.modalBox}
				>
					<Modal.Body>
						<IconButton
                            onClick={() => {
								setexportAnswerM(false);
							}}
                            disabled={exportingComment}
                            className={classes.modalCancelButton}
                            size="large">
							<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "40px", height: "40px" }}
							/>
						</IconButton>
						<div style={{ padding: "2rem" }}>
							<h5 style={{ fontWeight: "bold", marginTop: "20px" }}>
								{t("Are you sure you want to export the selected answers to 'Ideas and comments' page ?")}
							</h5>
							<div
								id="ideabox"
								className={
									!exportAnswerB ? classes.showElement : classes.hideElement
								}
							>
								<Form>
									<ActionButton
										onClick={(e) => exportComment(e)}
										style={{ float: "right" }}
										disabled={exportingComment}
										className={classes.submitButton}
									>
										{"Yes"}
									</ActionButton>
									<ActionButton
										onClick={(e) => exportCloseModal(e)}
										disabled={exportingComment}
										className={classes.submitButton}
									>
										{"No"}
									</ActionButton>
								</Form>
							</div>
							{exportingComment && (
								<div style={{ paddingTop: "20px" }}>
									<img
										className="responsive"
										src={loadinggif}
										width="25"
										alt=""
									/>
								</div>
							)}
							<div
								style={{
									margin: "30px 0 60px 0",
									textAlign: "center",
									position: "relative",
									zIndex: "9999",
								}}
								className={
									"responsive " +
									(exportAnswerB ? classes.showElement : classes.hideElement)
								}
							>
								<span>{"Answers exported"}</span>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
        );
	} else {
		return (
			<div className={classes.containerAnalytics} id="testscroll">
				<div style={{margin:"0 0 50px 0",textAlign:"center"}}>
				<span>{t("No results")}</span>
				</div>
			</div>
		);
	}
}
export default withRouter(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(withTranslation()(AnalyticsExercise)));