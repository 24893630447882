import React from 'react';
import NewAppIFrame from 'components/NewAppIFrameViewer';


const UserActions = () => {
  return (
    <NewAppIFrame
      iFramePagePath={"analytics/user-actions"}
      title="User Actions"
    />
  );
};
export default UserActions;