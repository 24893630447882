import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import TextArea from "components/CustomInput/TextArea";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { ourDarkGreen, standardRadious } from "assets/jss/incept-sustainability-variables.jsx";

const styles = {
    root: {
        "@media only screen and (max-width: 600px)": {
            margin: "0 20px 0 10px"
        }
    },
    buttonContainer: {
        textAlign: "right",
        marginTop: "20px"
    },
    cardAnswer: {
        borderRadius: standardRadious,
        "@media only screen and (max-width: 960px)": {
            borderRadius: "1rem",
            paddingTop: "17px"
        }
    },
    showmessage: {
        display: "block",
        color: ourDarkGreen
    },
    nshowmessage: {
        display: "none"
    },
    footertext: {
        fontStyle: "italic",
        fontSize: "11px"
    },
    textField: {
        '& .MuiInput-underline:after ': {
            borderBottom: "2px solid " + ourDarkGreen,
        }
    }
};

const SelfWriting = ({ 
    classes, 
    t, 
    save2, 
    inkey, 
    exercise_id, 
    datasav2 
}) => {
    const [userAnswer, setUserAnswer] = useState(datasav2?.content || "");
	const [messagesent, setMessagesent] = useState(typeof datasav2 !== "undefined");

	useEffect(() => {
		if (typeof datasav2 !== "undefined" && datasav2?.content) {
			setUserAnswer(datasav2.content);
		}
	}, [datasav2]);

    const handleChange = (e) => {
        setUserAnswer(e.target.value);
        setMessagesent(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        save2("Selfwriting", userAnswer, inkey, exercise_id);
        setMessagesent(true);
    };

    return (
        <div className={classes.root}>
            <Card className={classes.cardAnswer}>
                <CardBody className={classes.answerBox} isexercise={true}>
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Container>
                            <TextArea
                                onChange={handleChange}
                                value={userAnswer}
                                borderColor={ourDarkGreen}
                                borderRadius={standardRadious}
                            />
                            <p className={classes.footertext}>
                                {t('no personal data', {
                                    defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'
                                })}
                            </p>
                            <p className={messagesent ? classes.showmessage : classes.nshowmessage}>
                                {t('Thank you for submitting your answer')}
                            </p>
                        </Container>
                        <Container className={classes.buttonContainer}>
                            <ActionButton
                                style={{width:"auto"}}
                                type="submit"
                            >
                                {t('Submit')}
                            </ActionButton>
                        </Container>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default withTranslation()(withStyles(styles)(SelfWriting));