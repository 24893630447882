import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useIframeMessaging } from './hooks/useIframeMessaging';
import i18next from 'i18next';
import { useSharedTokenInfo } from 'context/auth_context/TokenContext';
import jwt from 'jwt-decode';
import clientConfig from 'client-config.js';

const iFrameOrigin = clientConfig.nextUrl; // Production Mode
// const iFrameOrigin = 'http://localhost:3010/'; // Development Mode

const NewAppIFrame = ({iFramePagePath, title }) => {

    const {authString} = useSharedTokenInfo();
    const userId = (authString || authString === "null") ? "":  jwt(authString).data.user.id;

    const [language, setLanguage] = useState(() => localStorage.getItem('preferredLanguage') || 'en');

    const iframeRef = useRef(null);
    const [isMounted, setIsMounted] = useState(false);

    const initialMessages = useMemo(() => [
        { type: 'userId', value: userId },
        { type: 'auth', value: authString },
    ], [authString, language, userId]);

    const { handleLoad } = useIframeMessaging(iframeRef, iFrameOrigin, initialMessages, iFramePagePath, language);

    if (!authString || authString === 'null') {
        return null; 
    }
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        }
    }, []);

    useEffect (() =>{
        i18next.on('languageChanged', (lng) => {
            setLanguage(lng);
        });
    }, [])
    
    if (!isMounted) {
        return null;
    }
    
    return (
        <iframe
            id='iframe-id'
            ref={iframeRef}
            src={`${iFrameOrigin}${language}/${iFramePagePath}`}
            style={{ width: '100%', height: '94dvh', border: 'none', overflow: 'hidden'}}
            onLoad={handleLoad}
            title={title}
        />
    );
};

export default NewAppIFrame;