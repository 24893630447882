import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';
import Container from "@mui/material/Container";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Collapse from "@mui/material/Collapse";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import { exerciseStyle } from "assets/jss/incept-sustainability-variables.jsx";
import { ourLightGreen } from "assets/jss/incept-sustainability-variables.jsx";
import IdeaGeneratorGrey from "assets/img/idea_generator_grey.svg";
import IdeaGeneratorGreen from "assets/img/idea_generator_green.svg";
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
import arrowdown from 'assets/img/down-arrow.svg';
import IdeaGeneratorBody from "components/Exercise/IdeaGeneratorBody";

const styles = {
    root: {},
    cardExercise: {
        backgroundColor: ourLightGreen,
        marginBottom: "10px",
        minHeight: "110px",
        marginTop: "0"
    },
    cardHeader: {
        fontSize: exerciseStyle.title.fontSize,
    },
    cardHeaderTitle: {
        fontSize: exerciseStyle.title.fontSize,
        marginTop: "10px",
        fontWeight: "bold",
    },
    iconDownArrow: {
        cursor: "pointer",
    },
    container: {
        "@media only screen and (max-width: 960px)": {
            padding: "0",
        }
    }
    // ... other styles remain the same
};

const IdeaGenerator = ({
    classes,
    children,
    title,
    background_color,
    type,
    subtype,
    showcard: initialShowCard,
    openSection,
    smoothtrans,
    t,
    isMobile,
    id,
    onSubmit,
    value,
    moduleId
}) => {

    const [showCard, setShowCard] = useState(initialShowCard);

    const color = (background_color !== undefined && background_color !== "") 
        ? { backgroundColor: "#" + background_color } 
        : {};
    
    const stylePaddingBody = (initialShowCard) 
        ? { padding: "0 30px 30px 30px" } 
        : { padding: "0 30px" };

    const handleOpenSection = () => {
        if (openSection) {
            openSection();
            setShowCard(prev => !prev);
        }
    };

    return (
        <div className={classes.root}>
            <Container className={classes.container}>
                <Card className={classes.cardExercise} style={color}>
                    <CardBody
                        style={isMobile ? { padding: "0 0 0 30px" } : stylePaddingBody}
                    >
                        <span activity_type={`${type} ${subtype}`}></span>

                        <GridContainer 
                            justifyContent="center"
                            style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                minHeight: "110px",
                                flexWrap: isMobile ? "wrap" : "nowrap",
                                flexDirection: isMobile ? "column" : "row",
                                gap: isMobile ? "1rem" : undefined,
                                margin: isMobile ? "20px 0" : "auto",
                            }}
                        >
                            <GridItem style={{ width: "100%" }} spaceLeft={0}>
                                <GridContainer
                                    alignItems="center"
                                    justifyContent="space-between"
                                    style={isMobile 
                                        ? { width: "100%", flexDirection: "row" } 
                                        : { width: "100%", flexDirection: "row", flexWrap: "nowrap" }
                                    }
                                >
                                    <GuideCardTitle style={{ marginBottom: "0" }}>
                                        <GridContainer
                                            style={{
                                                alignItems: "center",
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                gap: "1rem",
                                                flexGrow: "1",
                                                width: "100%"
                                            }}
                                        >
                                            <GridItem space={0} spaceNoPadding={true}>
                                                <img
                                                    src={ 
                                                        value ? IdeaGeneratorGreen : IdeaGeneratorGrey
                                                    }
                                                    className={classes.dotimage2}
                                                    width="80px"
                                                    style={{ paddingRight: "20px" }}
                                                    alt='Information'
                                                />
                                            </GridItem>
                                            <GridItem
                                                md="auto"
                                                lg="auto"
                                                spaceLeft={0}
                                                style={{ flexGrow: "1", width: "100%", maxWidth: "100%" }}
                                            >
                                                <span>{`${t("Idea generator")}: ${title}`}</span>
                                            </GridItem>
                                        </GridContainer>
                                    </GuideCardTitle>
                                </GridContainer>
                            </GridItem>
                            
                            <img
                                src={arrowdown}
                                style={{
                                    transform: showCard ? "rotate(-180deg)" : undefined,
                                    display: "block",
                                    margin: isMobile ? "0" : "0 auto",
                                    flexGrow: 0,
                                    marginLeft: isMobile ? "-30px" : "0"
                                }}
                                alt={t("Go to next section")}
                                title={t("Go to next section")}
                                onClick={handleOpenSection}
                                className={classes.iconDownArrow}
                            />
                        </GridContainer>

                        <Collapse 
                            in={showCard}
                            style={{ margin: isMobile ? "10px 0 20px -20px" : "auto" }}
                            addEndListener={() => {
                                if (showCard) {
                                    console.log("Scrolling exercise");
                                    setTimeout(() => { smoothtrans.scrollIntoView({ behavior: "smooth" }) }, 100);
                                }
                            }}
                        >
                            <div style={{ marginBottom: "20px" }}></div>
                            {children}

                            <IdeaGeneratorBody
                                key={id}
                                ideaId={id}
                                value={value}
                                onSubmit={onSubmit}
                                moduleId={moduleId}
                            />
                        </Collapse>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

IdeaGenerator.defaultProps = {
    children: <p>Here comes the content for this activity</p>,
    title: "Activity",
};

// Wrap with higher-order components
export default withTranslation()(
    withMyHook(
        withStyles(styles)(IdeaGenerator)
    )
);

// Note: This requires the withMyHook function from the original component
function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
        return <Component {...props} isMobile={mobile} />;
    }
}