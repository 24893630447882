import React from 'react'
import NewAppIFrame from 'components/NewAppIFrameViewer';

const ConsultRamboll = () => {  
  return (
    <NewAppIFrame
      iFramePagePath={`consult-ramboll`}
      title="Consult Ramboll"
    />
  )
    
}

export default ConsultRamboll