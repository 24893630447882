/*!
 * Description: this file contains the component for
 *  the type of exercise MultiChoiceTextBox
 * Components: MultiChoiceTextBox
 */
import React from "react";
import ReactHtmlParser from "react-html-parser";

// Styles
import withStyles from "@mui/styles/withStyles";

// Style components
import Container from "@mui/material/Container";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// Forms
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton";
import { grayCape20,standardRadious, ourDarkGreen, ourMediumGreen, ourGreen60,ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';
import checked from 'assets/img/checkedWhiteBg.png';
//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {},
	roottextfield: {
		'& .MuiInput-underline:before': {
			borderBottomColor: ourDarkGreen,
		  },
		'& .MuiInput-underline:after': {
			borderBottomColor: ourDarkGreen,
		},
	},
	blockContainer: {
		height: "100%",
		padding: "10px 0 10px 0",
	},
	paddingRightcl: {
		paddingRight: "0",
		"@media only screen and (max-width: 600px)": {
			paddingRight: "",
		},
		
	},
	paddingRightcleven: {
		paddingRight: "15px",
		"@media only screen and (max-width: 960px)": {
			display: "flex",
			flexwrap: "wrap",
			alignSelf: "center",
        },
		"@media only screen and (max-width: 600px)": {
			paddingRight: "0",
		},
	},
	cardContainer: {
		height: "100%",
		margin: "0",
		backgroundColor: 'transparent',
	},
	formControlLabelRoot: {
		margin: "0 auto",
		height: "100%",
		color: ourDarkGreen,
		width: "100%",
		"@media only screen and (max-width: 960px)": {
			textAlign: "center",
		},
	},
	formControlLabelLabel: {
		fontWeight: '500',
		border: '1px solid '+ourDarkGreen,
		borderRadius: standardRadious,
		padding: "1rem",
		height: "100%",
		"@media only screen and (min-width: 960px)": {
			width: "100%",
		},
		"&:hover": {
			//boxShadow: 'inset 1px 1px 6px 4px '+ourGreen60,
			//border: '1px solid '+ourOrange,
			backgroundColor: ourMediumGreen,
		},
		".Mui-checked ~ &": {
			backgroundColor: ourMediumGreen,
		},
		'&:active': {
			boxShadow: 'inset 1px 1px 10px 18px '+grayCape20,
		}
	},
	gridItem: {
		textAlign: "center",
		paddingRight: '10px',
		paddingBottom: '50px',
	},
	labelTitle: {
		fontSize: "24px",
		color: ourDarkGreen,
		marginBottom: "10px",
		fontWeight: '500',
	},
	cardContainerForTextField: {
		padding: "10px 20px",
	},
	textField: {
		"& .MuiInput-input": {
			color: ourDarkGreen
		}
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px",
		//marginBottom: "60px",
		paddingRight:"0",
		"@media only screen and (max-width: 960px)": {
            paddingRight: "20px",
        }
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginTop: "20px",
		"@media only screen and (max-width: 960px)": {
            paddingRight: "20px",
        }
	},
	footertext: {
		fontStyle: "italic",
		fontSize: "11px"
	}
};

/*
 * component that renders multi choice with a text box
 */
class MultiChoiceTextBox extends React.Component {
	constructor(props) {
		super(props);
		const { exercise } = props;
		const { options } = exercise;
		this.state = {
			selectedAnswers: [...new Array(options.length)].map(()=> ({'statement_id':-1,'value':false})),
			// selectedAnswers : [],
			lastSelectedAnswer: -1,
			explanation: "",
			openAlert: false,
			submitStatus: "",
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		};
		for (let i=0;i<options.length;i++) {
			this.state.selectedAnswers[i].statement_id = options[i].statement_id;
		}
		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswers = this.props.datasav2["content"];
			this.state.explanation = 
					(this.props.datasav2["content"].length>this.props.exercise.options.length) ?
					this.props.datasav2["content"][this.props.exercise.options.length].value: "";
		}
	}

	/**
	 * 
	 * @param {EventTarget} e 
	 */
	handleChange(e) {
		// First we need to figure out which option was selected
		const answerIndex = parseInt(e.target.value);

		// Then we need to figure out if it was checked or unchecked
		const setToBool = !this.state.selectedAnswers[answerIndex].value;
		this.setState({ submittedanswer: false });

		// Then we need to check if we are allowed to change it due to maxAllowed
		this.setState((state) => {
			const stateAnswers = state.selectedAnswers;
			const maxAllowedAnswers = this.props.exercise.maxAllowed;
			let lastSelectedAnswer = state.lastSelectedAnswer;

			// If we reload screen and have submitted, then we need to check if lastSelectedAnswer is set, if its not, set it with one of the selected answers
			if (lastSelectedAnswer === -1 && stateAnswers.find((answer) => answer.value)) {
				// Lambbda function to find the index of the first selected answer which is true
				lastSelectedAnswer = stateAnswers.findIndex((i) => i.value);
			}

			// First lets check we are under the max number of answers
			if (stateAnswers.filter((i) => i.value && typeof i.value !== "string").length < maxAllowedAnswers) {
				// Then we set the current answer to true
				stateAnswers[answerIndex].value = setToBool;
				// Then we set the last selected answer to the current answer
				console.log("changed to", stateAnswers)
				return { selectedAnswers: stateAnswers, lastSelectedAnswer: answerIndex };
			} else {
				// If we already have chosen the answer, then we need to set it to false and not change the last selected answer
				if (stateAnswers[answerIndex].value) {
					stateAnswers[answerIndex].value = setToBool;
					return { selectedAnswers: stateAnswers };
				} else {
				// If we already have the max number of answers selected, then we need to make the last selected answer the new current answer
				// Then the current answer should take the value of the last selected answer
				stateAnswers[lastSelectedAnswer].value = false;
				stateAnswers[answerIndex].value = setToBool;
				return { selectedAnswers: stateAnswers, lastSelectedAnswer: answerIndex,  openAlert: true };
				}				
			}
		});
	}

	handleChange2(e) {
		const answer = e.target.value;
		this.setState({ submittedanswer: false });
		this.setState({
			explanation: answer,
		});
	}

	handleSubmit(e) {
		if (this.state.explanation!=="") {
			this.setState((state) => {
				const sa = state.selectedAnswers;
				sa[this.props.exercise.options.length] = {"statement_id":-1,"value":state.explanation};
				this.props.save2("MultiChoiceText", sa, this.props.inkey, this.props.exercise_id);
				return { selectedAnswers: sa,submittedanswer: true };
			});
		}
		else {
			//Commented to allow empty answers
			/*let allFalse = this.state.selectedAnswers.every(function (e) {
				return !e.value;
			});
			if (!allFalse) {*/
				this.setState({ submittedanswer: true });
				this.props.save2("MultiChoiceText", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
			//}
		}
	}

	/*handleChangeTextArea(e) {
		document.getElementById("thankudiv").style.display = "block";
		let inputVal = document.getElementById("inputField");
		if (inputVal.value === "") {
			this.state.submitStatus = "Please write an idea here";
		} else {
			this.state.submitStatus = "Thank you for submitting your idea";
			this.state.disableInputField = true;
			this.state.disableInputField2 = false;
		}

		this.setState((state) => {
			const sa = state.selectedAnswers;
			sa[this.props.exercise.options.length] = {"statement_id":-1,"value":state.explanation};
			//this.props.save2("MultiChoiceText", sa, this.props.inkey, this.props.exercise_id);
			return { selectedAnswers: sa };
		});
	}*/

	/*handleChangeTextAreaEdit(e) {
		this.setState({
			disableInputField: false,
			disableInputField2: true,
			submitStatus: ""
		});
	}*/

	_renderOptionBlock(option, answerIndex) {
		const { classes } = this.props;
		const { title, statement } = option;

		const checkbox = <Checkbox color="default" style={{ display: "none" }} />;

		const label = (
			<React.Fragment>
				<div className={classes.labelTitle}>{title}</div>
				<div className={classes.labelStatement}>{ReactHtmlParser(statement)}</div>
			</React.Fragment>
		);
		const odd = (answerIndex % 2) ? classes.paddingRightcl:classes.paddingRightcleven;

		return (
			<GridItem xs={12} sm={6} key={answerIndex} space={0} className={classes.gridItem}>
				<div className={classes.blockContainer+" "+odd} style={{height: "100%"}}>
					<FormControlLabel
						classes={{
							root: classes.formControlLabelRoot,
							label: classes.formControlLabelLabel,
						}}
						checked={this.state.selectedAnswers[answerIndex].value}
						control={checkbox}
						label={label}
						onChange={(e) => this.handleChange(e)}
						value={answerIndex}
					/>
				</div>
			</GridItem>
		);
	}

	_renderTextField(textField) {
		const { classes, t } = this.props;
		const { title, placeholder } = textField;

		return (
			<GridItem xs={12} sm={6} className={classes.gridItem}>
				<div className={classes.blockContainer}>
					<div style={{padding: "20px 20px 0 20px"}}>
						<div className={classes.labelTitle}>{title}</div>
						<TextField
							id="inputField"
							className={classes.textField}
							placeholder={placeholder}
							onChange={(e) => this.handleChange2(e)}
							value={this.state.explanation}
							fullWidth
							color={"nocolor"}
							style={{padding: "10px 0"}}
							classes={{
								   root: classes.roottextfield
							 }}
						/>
						<p className={classes.footertext}>
							{t('no personal data',
								{
									defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'})}
							</p>
						<div
							id="thankudiv"
							style={{ color: `${this.state.disableInputField ? "green" : "red"}`, 
							marginBottom: "20px", display: "none" }}
						>
							{this.state.submitStatus}
						</div>
					</div>
				</div>
			</GridItem>
		);
	}

	handleClose(e, reason) {
		if (reason === "clickaway") {
			return;
		}
		this.setState({ openAlert: false });
	}

	_renderOptions(options, textField) {
		const {  t } = this.props;
		const st = options.map((option, answerIndex) =>
			this._renderOptionBlock(option, answerIndex)
		);

		const tx = this._renderTextField(textField);

		return (
			<GridContainer justifyContent="space-evenly" style={{ marginTop: "50px" }}>
				{st}
				{textField.title && tx}
				<Snackbar
					open={this.state.openAlert}
					autoHideDuration={3000}
					onClose={(e, reason) => this.handleClose(e, reason)}
					message={t('You must choose up to ')+this.props.exercise.maxAllowed}
				></Snackbar>
			</GridContainer>
		);
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { options, textField } = exercise;
		return (
			<div className={classes.root}>
				{this._renderOptions(options, textField)}
				<div className={classes.statusContainer}>
				{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted")}</p>}
				</div>
				<Container className={classes.buttonContainer}>
					<ActionButton
						style={{width:"auto"}}
						type="submit"
						centered={true}
						onClick={(e) => this.handleSubmit(e)}
					>
						{t('Submit')}
					</ActionButton>
				</Container>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(MultiChoiceTextBox));
