import React from 'react';
import NewAppIFrame from 'components/NewAppIFrameViewer';

const AnalyticsOverview = (props) => {

  return (
    <NewAppIFrame
      // iFrameOrigin={clientConfig.nextUrl} // Production Mode
      iFramePagePath={"analytics"}
      title="Analytics Overview"
    />
  );
};
export default AnalyticsOverview;