import React from "react";
import { ourGreen, ourDarkGreen, ourLightGreen } from "assets/jss/incept-sustainability-variables.jsx";

const SustainabilityLevelBar = ({ level }) => {
  const totalBoxes = 10;
  const boxes = Array.from({ length: totalBoxes }, (_, i) => i < level);

  

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '3rem'}}>
      {boxes.map((box, index) => (
        <div
          key={index}
          style={{
            width: '8%',
            height: '20px',
            backgroundColor: box ? ourGreen : ourLightGreen,
            marginRight: index !== totalBoxes - 1 ? '2%' : '0',
          }}
        />
      ))}
    </div>
  );
};


export default SustainabilityLevelBar;