import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Card from "components/Card/Card.jsx";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { ourDarkGreen, standardRadious } from "assets/jss/incept-sustainability-variables.jsx";
import StarRating from "./StarRating";
import TextArea from "components/CustomInput/TextArea";
import axios from "axios";
import { module_rating } from "assets/jss/incept-sustainability-variables";
import { useSharedTokenInfo } from "context/auth_context/TokenContext";

const styles = {
    root: {
        "@media only screen and (max-width: 600px)": {
            margin: "0 20px 0 10px"
        }
    },
    buttonContainer: {
        textAlign: "right",
        marginTop: "10px"
    },
    cardAnswer: {
        borderRadius: standardRadious,
        "@media only screen and (max-width: 960px)": {
            borderRadius: "1rem",
            paddingTop: "17px"
        }
    },
    showmessage: {
        display: "block",
        color: ourDarkGreen
    },
    nshowmessage: {
        display: "none"
    },
    footertext: {
        fontStyle: "italic",
        fontSize: "11px",
        marginBottom: "0px"
    },
    textField: {
        '& .MuiInput-underline:after ': {
            borderBottom: "2px solid " + ourDarkGreen,
        }
    },
    feedbackContainer: {
        opacity: 0,
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'opacity 1s ease-in-out, max-height 1s ease-in-out',
        '&.visible': {
            opacity: 1,
            maxHeight: '500px' // Adjust this value based on your content
        }
    }
};

const SelfRating = ({ 
    classes,
    moduleId,
    onSubmit,
    value,
    reason,
    t
}) => {

    const {authString} = useSharedTokenInfo();
    const authHeader = `Bearer ${authString}`;

    const [userRating, setUserRating] = useState(value || 0);
    const [userReason, setUserReason] = useState(reason || "");

    const [messageSent, setMessageSent] = useState(false);

    const handleSubmit = async() => {
        const endpoint = module_rating(moduleId);
        try {
            await axios.post(endpoint, {
            rating: userRating,
            comment: userReason
            }, {
            headers: {
                'Authorization': `${authHeader}`
            }
            });
            onSubmit?.(userRating, userReason);
            setMessageSent(true);

            setTimeout(() => {
                setMessageSent(false);
            }, 4000);
        } catch (error) {
            console.error("Error submitting rating:", error);
        }
    }

    return (
        <div className={classes.root}>
            <Card style={{border: `1px solid ${ourDarkGreen}`, marginBottom: "5px"}}>
                  <StarRating defaultRating={userRating} onChange={setUserRating}/>
                  
                <div className={`${classes.feedbackContainer} ${userRating !== 0 ? 'visible' : ''}`}>
                      <Container>
                          <p style={{fontWeight: "bold"}}>
                              {t('pleaseShareWhy')}
                          </p>
                         <TextArea
                              value={userReason}
                              onChange={(e) => setUserReason(e.target.value)}
                              placeholder={t("I found the module to be...")}
                              borderColor={ourDarkGreen}
                              borderRadius={standardRadious}
                              variant="standard"
                              sx={{marginTop: "-20px",'& ::placeholder':{fontSize:'small'} }}
                              InputProps = {{
                                  disableUnderline: true
                              }}
                               />
                          </Container>
                    </div>
            </Card>
            {
                userRating !== 0 && (
                    <p className={classes.footertext}>
                     {t('no personal data', {
                            defaultValue: 'Please keep your answer anonymous, do not write any personal data in your answer.'
                        })}
                </p>
                )
            }

            {
                messageSent && (
                    <p className={classes.showmessage}>
                        {t('Thank you for submitting your answer')}
                    </p>
                )
            }

            <Container className={classes.buttonContainer}>
                            <ActionButton
                                style={{width:"auto"}}
                                toolTip = {userRating === 0 && t('Please provide a rating')}
                                type="submit"
                                disabled={userRating === 0}
                                onClick={handleSubmit}
                            >
                                {t('Submit rating')}
                            </ActionButton>
            </Container>
        </div>
    );
};

export default withTranslation()(withStyles(styles)(SelfRating));